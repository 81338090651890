import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ceil, debounce } from "lodash";
import moment from "moment";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Box, Stack, Typography, Checkbox, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import ReactHtmlParser from 'react-html-parser'

import { useStores } from "models";
import { optionsTranslation } from "utils";
import { BasicTable, FormInput } from "components";
import { IconShippingFast } from "assets/images";
import { colors } from "assets";

const columnHelper = createColumnHelper<any>();

interface CuppingResultsMultisample {
  data: any[],
  meta: {
    total: number
  }
}

interface CombinedCuppingResultsProps {
  id: string,
  onChangeSelectedCuppingResult: (sampleId: string, cuppingId: string | number) => void,
  selectedCuppingResult: Set<string>,
  cuppingResultsMultisample: CuppingResultsMultisample,
}

const PAGE_SIZE = 5;

export const CombinedCuppingResults = observer((props: CombinedCuppingResultsProps) => {
  const { id, selectedCuppingResult = new Set(), onChangeSelectedCuppingResult, cuppingResultsMultisample } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const {
    cuppingResultStore: { getCuppingResultsExternal },
  } = useStores();

  const initData = (cuppingResults: CuppingResultsMultisample) => {
    const { data, meta } = cuppingResults;
    const totalPage = ceil(meta.total / PAGE_SIZE);
    setTotalPage(totalPage);
    setData(data.splice(0, PAGE_SIZE));
    setIsInitiated(true);
  }

  const getCuppingResultsData = async (page: number, searchValue?: string) => {
    setIsFetching(true);
    try {
      const res: any = await getCuppingResultsExternal(id, PAGE_SIZE, page, searchValue || search);
      if (res.kind === "ok") {
        const totalPage = ceil(res.meta.pagination.records / PAGE_SIZE);
        setTotalPage(totalPage);
        setData(res.data);
      }
    } finally {
      setIsFetching(false)
    }
  };

  const debouncedFetch = useCallback(
    debounce((page: number, searchValue?: string) => {
      getCuppingResultsData(page, searchValue);
    }, 500),
    []
  );

  const onChangePage = (_, p) => {
    setPage(p);
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Box />,
      cell: info => {
        const value = info.renderValue();
        const isSelected = selectedCuppingResult.has(value.toString());
        return (
          <Box textAlign="left">
            <Checkbox
              checked={isSelected}
              onChange={() => onChangeSelectedCuppingResult(id, value)}
            />
          </Box>
        )
      }
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingSessionId')}</Box>,
      cell: info =>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Typography>{info.renderValue()}</Typography>
          {info.row.original.type === 'SampleTransactionItemScore' && (
            <IconShippingFast width={32} height={32} fill={colors.primary.main} />
          )}
        </Stack>,
    }),
    columnHelper.accessor('cupperName', {
      id: 'cupperName',
      header: () => <Box textAlign="left">{t('sample.detail.cupperName')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingDate', {
      id: 'cuppingDate',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingDate')}</Box>,
      cell: info => moment(info.renderValue()).format('L'),
    }),
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('sample.detail.sampleType')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('salesContract', {
      id: 'salesContract',
      header: () => <Box textAlign="left">{t('sample.detail.salesContract')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingProtocol', {
      id: 'cuppingProtocol',
      header: () => <Box textAlign="left">{t('sample.detail.cuppingProtocol')}</Box>,
      cell: info => optionsTranslation('cuppingProtocol', info.renderValue()),
    }),
    columnHelper.accessor('totalDefect', {
      id: 'totalDefect',
      header: () => t('sample.detail.totalDefect'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('totalScore', {
      id: 'totalScore',
      header: () => t('sample.detail.totalScore'),
      cell: info => <Box textAlign="center">{info.renderValue()}</Box>,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    initData(cuppingResultsMultisample);
  }, [cuppingResultsMultisample]);

  useEffect(() => {
    if (isInitiated) {
      getCuppingResultsData(page);
    }
  }, [page]);

  useEffect(() => {
    if (isInitiated) {
      setPage(1);
      debouncedFetch(1, search);
    }
    return () => debouncedFetch.cancel();
  }, [search, debouncedFetch]);

  return (
    <Box px={2} py={2.5} pt={0}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography fontWeight="600">
            {t('generateReport.cuppingResult.title')}
          </Typography>
          {ReactHtmlParser(t('generateReport.cuppingResult.descriptionHTML'))}
        </Box>
        <FormInput
          textFieldProps={{
            placeholder: `${t('common.search')}...`,
            value: search,
            onChange: (e) => {
              setSearch(e.target.value)
            },
            InputProps: {
              endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
            }
          }}
          formControlProps={{ margin: 'none', fullWidth: false }}
        />
      </Stack>
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        isFetching={isFetching}
        paginationStyle={{ mb: 0 }}
      />
    </Box>
  )
})
