import React, {useState} from "react";
import {cloneDeep, isEmpty} from "lodash";
import {useTranslation} from "react-i18next";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Box, Button, Chip, MenuItem, Select, Stack, Typography} from "@mui/material";

import {fulfillmentTypes, subOrderStatus} from "constants/form";
import {BasicTable, FormInput, ModalWrapper} from "components";
import {optionsTranslation} from "utils";

import {useStyles} from "./shipment-table.styles";
import {Add} from "@mui/icons-material";
import { ThirdPartyEmailModal } from "components/third-party-email-modal/third-party-email-modal";

const columnHelper = createColumnHelper<any>()

export const ShipmentTable = (props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { groupSamplesToBeShipment, group, onSubmitEmail, onUpdateStatus, onUpdateNote, onCreateShipment, isFormComplete, isFormCompleteWithoutEmail } = props

  const [openEmailModal, setOpenEmailModal] = useState(false)
  const data = cloneDeep(groupSamplesToBeShipment[group])
  const fulfilmentType = data[0].sampleFulfillmentType
  const sampleLocation = data[0].sampleLocation
  const thirdPartyEmail = data[0].thirdPartyEmail
  const checkFormComplete = fulfilmentType === fulfillmentTypes[0] ? isFormCompleteWithoutEmail : isFormComplete

  const renderValue = (info) => {
    if (info.renderValue()) return info.renderValue()

    return <Box textAlign="center">-</Box>
  }

  const handleSubmitEmail = (emails: string) => {
    onSubmitEmail && onSubmitEmail(emails, fulfilmentType, sampleLocation);
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('shipment.table.type')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),
    columnHelper.accessor('sample.purchaseGrade', {
      id: 'sample.purchaseGrade',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseGrade')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.warehouseReference', {
      id: 'sample.warehouseReference',
      header: () => <Box textAlign="left">{t('shipment.table.warehouse')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.cargoNumber', {
      id: 'sample.cargoNumber',
      header: () => <Box textAlign="left">{t('shipment.table.cargo')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.customer', {
      id: 'sample.customer',
      header: () => <Box textAlign="left">{t('shipment.table.customer')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.purchaseContractReference', {
      id: 'sample.purchaseContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.salesContractReference', {
      id: 'sample.salesContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.salesContract')}</Box>,
      cell: renderValue,
    }),
  ]

  const shipmentTable = useReactTable({
    data : data.filter( x => x.approvalStatus !== "declined"),
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Box className={classes.wrapper}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={thirdPartyEmail ? 2 : 1}>
        <Typography variant='h6'>
          {group === fulfillmentTypes[0] ?
            t('shipment.localShipment') :
            `${t('shipment.thirdPartyShipment')} (${group})`
          }
        </Typography>
        <Box>
        <Select
          size='small'
          value={isEmpty(groupSamplesToBeShipment[group][0].subOrderStatus) ? subOrderStatus[0]: groupSamplesToBeShipment[group][0].subOrderStatus}
          onChange={(e) => {
            // resetRefInput()
            onUpdateStatus(e.target.value, fulfilmentType, sampleLocation)
          }}
          sx={{mr: 1, height: 33}}
          disabled={groupSamplesToBeShipment[group][0].isCreatedShipment}
        >
          {subOrderStatus.filter((x) => {
            if(groupSamplesToBeShipment[group][0].isCreatedShipment){
              return x === subOrderStatus[3]
            } 
              return x !== subOrderStatus[3]
          }).map(item =>
            <MenuItem key={item} value={item}>{optionsTranslation('fulfillmentStatus', item)}</MenuItem>
          )}
        </Select>
        {group !== fulfillmentTypes[0] &&
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            disabled={groupSamplesToBeShipment[group][0].isCreatedShipment}
            onClick={() => setOpenEmailModal(true)}
          >
            {t("shipment.button.addEmail")}
          </Button>
        }
        </Box>
      </Stack>
      {thirdPartyEmail && (
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <Typography>{t('shipment.thirdPartyEmail')}:</Typography>
          {thirdPartyEmail.split(',').map(email => (
            <Chip key={email} label={email.trim()} color="primary" />
          ))}
        </Stack>
      )}

      <BasicTable table={shipmentTable} />
      <Box mt={2}>
        <FormInput
          label={t('shipment.table.note')}
          textFieldProps={{
            value: isEmpty(groupSamplesToBeShipment[group][0].notes)? '' : groupSamplesToBeShipment[group][0].notes,
            onChange: (e) => onUpdateNote(e.target.value, fulfilmentType, sampleLocation),
            multiline: true,
            rows: 2,
          }}
          formControlProps={{
            sx: {
              backgroundColor: 'transparent',
              '& .MuiInputBase-formControl': {
                backgroundColor: 'white'
              }
            }
          }}
        />
      </Box>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" >
        <Button
          variant="contained"
          sx={{width: 200}}
          disabled={groupSamplesToBeShipment[group][0].isCreatedShipment || !checkFormComplete}
          onClick={() => onCreateShipment(fulfilmentType, sampleLocation)}
        >
          {t(`shipment.button.createShipment`)}
        </Button>
      </Stack>

      <ThirdPartyEmailModal isOpen={openEmailModal} onClose={() => setOpenEmailModal(false)} onSubmit={handleSubmitEmail} thirdPartyEmail={thirdPartyEmail} />
    </Box>
  )
}
