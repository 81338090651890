/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-05 22:42:37
 * @modify date 2022-02-05 22:42:37
 */
import React from 'react'
import { Clear as ClearIcon } from '@mui/icons-material'
import {
  Modal,
  Container,
  Box,
  IconButton
} from '@mui/material'

import { ModalWrapperProps } from './modal-wrapper.props'
import { useStyles } from './modal-wrapper.styles'

export const ModalWrapper: React.FC<ModalWrapperProps> = (props: ModalWrapperProps) => {
  const classes = useStyles()
  const { open, onClose, maxWidth = 'lg', children, containerProps, modalProps, hideClose } = props

  return (
    <Modal
      open={open}
      {...(onClose && {onClose: onClose})}
      {...(modalProps && {...modalProps})}
    >
      <Container className={classes.wrapper} maxWidth={maxWidth} {...(containerProps && {...containerProps})}>
        {onClose && !hideClose &&
          <Box sx={{textAlign: 'right'}}>
            <IconButton
              size="small"
              onClick={onClose}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        }
        
        {children}
      </Container>
    </Modal>
  )
}