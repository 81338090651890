import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Button, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {DeleteOutlineOutlined, FileCopy} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import {BasicTable, ModalMessage, ModalWrapper} from "components";
import {sampleTypes} from "constants/form";
import {globalAction, optionsTranslation} from "utils";

import {useStyles} from "./sample-modal.styles";
import {findRoute} from 'constants/routes';
import { colors } from "assets";

const PREFIX = 'contracts'
const renderHeader = (text) => {
  return (
    <Box textAlign="left">
      {text}
    </Box>
  )
}

export const SampleModal = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const { open, onClose, samples, createSamples, notificationStore } = props
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const refInput = useRef('')
  const [errorRows, setErrorRows] = useState<number[]>([]);

  const masterSampleInformationLink = findRoute('masterSampleInformation')

  const onDeleteRow = (index) => {
    const newData = data.filter((_, i) => i !== index)
    setData(newData)
  }

  const onCopyRow = (index) => {
    const newData = [...data, data[index]]
    setData(newData)
  }

  const setValue = (index, key, value) => {
    const newData = data.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value
        }
      }
      return item
    })

    setData(newData)
  }

  const validateData = () => {
    const invalidRows = data
      .map((row, index) => (row.sampleType ? null : index))
      .filter((index) => index !== null) as number[];
    
    setErrorRows(invalidRows);
    return invalidRows.length === 0;
  };

  const onCreateSamples = async () => {
    if (!validateData()) {
      return;
    }

    try {
      setLoading(true)
      const res = await createSamples(data)
      // if (res) res.base?.map(message => notificationStore.setNotification(message))
      setOpenSuccess(true)
    } catch (e: any) {
      // e.errors.base?.map(message => notificationStore.setNotification(message))
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      accessorKey: 'purchaseGrade',
      header: () => renderHeader(t(`${PREFIX}.table.purchaseGrade`)),
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'purchaseContractReference',
      header: () => renderHeader(t(`${PREFIX}.table.purchaseContract`)),
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'salesContractReference',
      header: () => renderHeader(t(`${PREFIX}.table.salesContract`)),
      cell: info => info.getValue(),
    },
    {
      accessorKey: 'sampleType',
      header: () => renderHeader(t(`${PREFIX}.table.sampleType`)),
      cell: (info) => {
        const isError = errorRows.includes(info.row.index);
        return (
          <Select
            size="small"
            value={info.getValue() ?? ""}
            onChange={(e) => {
              refInput.current = "";
              setValue(info.row.index, "sampleType", e.target.value);
      
              if (errorRows.includes(info.row.index)) {
                setErrorRows((prev) => prev.filter((i) => i !== info.row.index));
              }
            }}
            error={isError}
            sx={{ borderColor: isError ? "error.main" : "inherit" }}
          >
            {sampleTypes.map((item) => (
              <MenuItem key={item} value={item}>
                {optionsTranslation("sampleType", item)}
              </MenuItem>
            ))}
          </Select>
        );
      }
    },
    {
      accessorKey: 'containerNumber',
      header: () => renderHeader(t(`${PREFIX}.table.containerNumber`)),
      cell: info => {
        const key = 'containerNumber' + info.row.index
        return (
          <TextField
            fullWidth
            size='small'
            autoFocus={key === refInput.current}
            value={info.getValue()}
            onChange={(e) => {
              refInput.current = key
              setValue(info.row.index, 'containerNumber', e.target.value)
            }}
          />
        )
      }
    },
    {
      accessorKey: 'referenceNumber',
      header: () => renderHeader(t(`${PREFIX}.table.referenceNumber`)),
      cell: info => {
        const key = 'referenceNumber' + info.row.index
        return (
          <TextField
            fullWidth
            size='small'
            autoFocus={key === refInput.current}
            value={info.getValue()}
            onChange={(e) => {
              refInput.current = key
              setValue(info.row.index, 'referenceNumber', e.target.value)
            }}
          />
        )
      }
    },
    {
      accessorKey: 'uniqueToken',
      header: '',
      cell: info => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color='error'
            onClick={() => onDeleteRow(info.row.index)}
            className={classes.button}
          >
            <DeleteOutlineOutlined />
          </Button>
          <Button
            variant="contained"
            onClick={() =>  onCopyRow(info.row.index)}
            className={classes.button}
          >
            <FileCopy />
          </Button>
        </Stack>
      )
    }
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (open) setData(samples)
  }, [open])

  if (openSuccess) {
    return (
      <ModalMessage
        open={openSuccess}
        onSubmit={() => {
          onClose()
          navigate(masterSampleInformationLink)
        }}
        buttonText={t(`${PREFIX}.createSample.success.button`)}
        description={t(`${PREFIX}.createSample.success.description`, { count: data.length })}
      />
    )
  }

  const isError = errorRows.length > 0;
  return (
    <ModalWrapper open={open}>
      <Typography variant="h4">
        {t(`${PREFIX}.createSample.title`)}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t(`${PREFIX}.createSample.description`)}
      </Typography>

      <BasicTable table={table} tableProps={{ className: classes.table }} />
      {
        isError ? <Typography mt={1} color={colors.error.primary} fontSize={14}>
          {t('contracts.createSample.sampleTypeError')}
        </Typography> : null
      }

      <Stack spacing={2} direction='row' justifyContent='end' mt={2}>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={loading}
        >
          {t(`common.cancel`)}
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onCreateSamples}
        >
          {t(`${PREFIX}.button.create`)}
        </LoadingButton>
      </Stack>
    </ModalWrapper>
  )
}
