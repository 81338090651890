/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-16 21:35:14
 * @modify date 2022-03-16 21:35:14
 */

import React, {useRef} from 'react'
import {
  groupBy,
  split,
  trim,
  map,
  debounce,
  clone,
  includes,
  pull,
} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Box,
  Grid,
  Typography,
  Stack,
  Popover,
  IconButton,
  SvgIcon,
  AppBar,
  Switch
} from '@mui/material'
import { Visibility, VisibilityOff, Email } from '@mui/icons-material'
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { isMobile } from "react-device-detect"

import { colors } from 'assets'
import { useStores } from 'models'
import { IconReportShare, Logo} from 'assets/images'
import { DEBOUNCE_TIME } from 'config/env'
import { findRoute } from 'constants/routes'
import { printPage, validateEmail } from 'utils'
import { FormInput, HeaderTitleWide, ModalWrapper } from 'components'
import { Report as ModuleSampleReport } from 'modules'

import { useStyles } from './sample-report.styles'
import {useReactToPrint} from "react-to-print";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export const SampleReport: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const locations = useLocation()
  const searchParams = new URLSearchParams(locations.search)

  const sampleLink = findRoute('reviewSamples')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')
  const printElement = useRef(null)

  const {
    sampleReportStore,
    sampleReportStore: {
      title, emails, message, isCuppingOwner, cuppingProtocol, scores, getSampleReport, getAverageScoresForGraph,
      getCloudWords, averageIntensities, allScoreDescriptors, getFlavorWheelOnlyFragrance, getFlavorWheelOnlyFlavor, getFlavorWheelGroups,
      getDefects, sendReportInvitation, setValue: setSampleReportValue, getSampleRecord: sampleRecord
    },
    userStore: { isAuthenticated, languagePreference },
    notificationStore: { setLoading, setNotification, setValue: setNotificationValue }
  } = useStores()

  const [isShowCupper, setIsShowCupper] = React.useState(false)
  const [isPrinting, setIsPrinting] = React.useState(false)
  const [anchorShare, setAnchorShare] = React.useState<HTMLButtonElement | null>(null)
  const openShare = Boolean(anchorShare)
  const [openShareEmail, setOpenShareEmail] = React.useState(false)
  const [isSendingEmail, setIsSendingEmail] = React.useState(false)
  const [isEmailError, setIsEmailError] = React.useState(false)
  const [openNps, setOpenNps] = React.useState(false)
  const [select, setSelect] = React.useState({})
  const [selectAll, setSelectAll] = React.useState(false)
  const [ids, setIds] = React.useState<String[]>([])
  const [emailTextHelper, setEmailTextHelper] = React.useState(t('report.emailShareSampleDescription'))
  const [groupDefects, setGroupDefects] = React.useState<any>({})

  const isScaDescriptive = cuppingProtocol === 'sca_descriptive'

  const reportUrl = window.location.href

  const handleSwitchAll = (select: boolean) => {
    setSelectAll(select);
    scores.map(item => {
      handleSwitch(select, item.id)
    })
  };

  const onSelectAll = () => {
    if(selectAll) return setIds([])

    setIds(map(scores, 'id'))
  }

  const handleSwitch =(select: boolean, id:string)  => {
    setSelect((values) => ({
      ...values,
      [id]: select
    }));
  }

  const onSelect = (id: string) => {
    const newIds = clone(ids)

    if (includes(ids, id)) {
      pull(newIds, id)

    } else {
      newIds.push(id)
    }
    setIds(newIds)
  }

  const shareByEmail = async() => {
    let isCorrectEmails = true
    setIsEmailError(false)
    setEmailTextHelper(t('report.emailShareSampleDescription'))

    split(emails, ',').forEach(email => { if (!validateEmail(trim(email))) isCorrectEmails = false })

    if (!isCorrectEmails) {
      setIsEmailError(true)
      setEmailTextHelper(t('report.error.emailIsInvalid'))
      return
    }

    setSampleReportValue('emails', emails.replace(/\s/g, ""))
    setIsSendingEmail(true)

    try{
      const res = await sendReportInvitation(params.id)
      res.base?.map(message => setNotification(message))

      setOpenShareEmail(false)
      setAnchorShare(null)
    } finally {
      setIsSendingEmail(false)
    }
  }

  const renderEmailModal = (
    <ModalWrapper
      maxWidth='sm'
      open={openShareEmail}
      onClose={() => setOpenShareEmail(false)}
    >
      <Typography variant='h5'>{t('report.shareReport')}</Typography>
      <FormInput
        label={t('report.to')}
        textFieldProps={{
          error: isEmailError,
          value: emails,
          onChange: (e) => setSampleReportValue('emails', e.target.value),
          helperText: emailTextHelper
        }}
      />
      <FormInput
        label={t('report.emailMessage')}
        textFieldProps={{
          multiline: true,
          minRows: 5,
          value: message,
          onChange: (e) => setSampleReportValue('message', e.target.value),
        }}
      />

      <Box sx={{mt: 2, textAlign: 'center'}}>
        <Button
          variant='outlined'
          onClick={() => setOpenShareEmail(false)}
          className='wide'
          sx={{mr: 2}}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={shareByEmail}
          className='wide'
          disabled={!emails && !message}
          loading={isSendingEmail}
        >
          {t('report.send')}
        </LoadingButton>
      </Box>
    </ModalWrapper>
  )

  const renderButtons = (
    <Stack direction='row' spacing={1} >
      {(isCuppingOwner && !isMobile) &&
        <Button
          size='small'
          variant='contained'
          startIcon={isShowCupper ? <VisibilityOff /> : <Visibility />}
          onClick={() => {
            setIsShowCupper(!isShowCupper)
            setOpenNps(true)
          }
          }
        >
          {isShowCupper ? t('report.hideCupper') : t('report.showCupper')}
        </Button>
      }
      {(isAuthenticated && !isMobile) &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ml: 1}}
            onClick={(event) => setAnchorShare(event.currentTarget)}
          >
            {t('report.share')}
          </Button>
          <Popover
            open={openShare}
            anchorEl={anchorShare}
            onClose={() => setAnchorShare(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {pt: 1, px: 2}
            }}
          >
            <IconButton color='primary' onClick={() => setOpenShareEmail(true)} sx={{pb: 2.5}}>
              <Email sx={{width: 24, height: 24}} />
            </IconButton>
            <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
              <FacebookIcon size={24} round />
            </FacebookShareButton>
            <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
              <LinkedinIcon size={24} round />
            </LinkedinShareButton>
            <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
              <TwitterIcon size={24} round />
            </TwitterShareButton>
            <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
              <WhatsappIcon size={24} round />
            </WhatsappShareButton>
          </Popover>
        </>
      }

      {!isMobile &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ ml: 1 }}
            onClick={() => setIsPrinting(true)}
          >
            {t('report.print')}
          </Button>
        </>
      }
    </Stack>
  )

  const onGetSampleReport = React.useCallback(
    debounce(async (token) => {
      try{
        setNotificationValue('loading', true)
        await getSampleReport(token)

        const defects = getDefects(searchParams.get('green_grading'))
        setGroupDefects(groupBy(defects, 'category'))
      } catch {
        navigate(-1)
      } finally {
        setNotificationValue('loading', false)
      }
    }, DEBOUNCE_TIME), []
  )

  const renderShareButtonMobileVersion = (
    <Box justifyContent="flex-start" sx={{ flexDirection: 'row', display: 'flex' }}>
      <Box><Typography>{t('sample.share')} :</Typography></Box>
      <Box sx={{mt: -1}}>
        <IconButton color='primary' onClick={() => setOpenShareEmail(true)} sx={{ pb: 3}}>
          <Email sx={{ width: 24, height: 24 }} />
        </IconButton>
        <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
          <FacebookIcon size={24} round />
        </FacebookShareButton>
        <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
          <LinkedinIcon size={24} round />
        </LinkedinShareButton>
        <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
          <TwitterIcon size={24} round />
        </TwitterShareButton>
        <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
          <WhatsappIcon size={24} round />
        </WhatsappShareButton>

      </Box>
    </Box>
  )

  const renderButtonMobileVersion = (
    <AppBar  position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white'}}>
      <Grid container spacing={1} sx={{mt: -2, p: 2}}>
        {isCuppingOwner &&
          <Grid item xs={6}>
            <Button
              fullWidth
              size='small'
              variant='contained'
              startIcon={isShowCupper ? <VisibilityOff /> : <Visibility />}
              onClick={() => setIsShowCupper(!isShowCupper)}
            >
              {isShowCupper ? t('report.hideCupper') : t('report.showCupper')}
            </Button>
          </Grid>
        }

        <Grid item xs={!isScaDescriptive ? 6: 12}>
          <Button
            fullWidth
            size='small'
            variant='contained'
            onClick={() => setIsPrinting(true)}
          >
            <SvgIcon component={IconReportShare} inheritViewBox sx={{ mr: 1, width: 15, height: 15 }} />{t('sample.export')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  )

  const renderContent = React.useCallback(() => {
    return printElement.current;
  }, [printElement.current]);

  const handlePrint = useReactToPrint({
    content: renderContent,
    documentTitle: "sample-report",
    onAfterPrint: () => setIsPrinting(false),
  });

  React.useEffect(() => {
    scores.map(item => {
      handleSwitch(false, item.id)
    })
  }, [])

  React.useEffect(() => {
    if (!params.id) return

    onGetSampleReport(params.id)
  }, [languagePreference])

  React.useEffect(() => {
    setSampleReportValue('message', t('report.messageShareSampleDescription', {
      sessionTitle: title
    }))
  }, [title])

  React.useEffect(() => {
    if (isPrinting) {
      handlePrint()
    }
  }, [isPrinting])

  return (
    <Box px={isMobile ? 0 : 4} py={isMobile ? 0 : 2.5}>
      <Box px={isMobile ? 2 : 0}>
      {!isPrinting &&
        <HeaderTitleWide
          title={title}
          rightContent={renderButtons}
          sx={{mb: '0 !important', '@media print': {display: 'none'}}}
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: sampleLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              {
                backUrl: sampleReviewLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleReview')}),
              }
            ]
          })}
        />
      }
      <Box ref={printElement} data-name='sample-report'>
        <ModuleSampleReport
          ids={ids}
          isShowCupper={isShowCupper}
          groupDefects={groupDefects}
          isPrinting={isPrinting}
          renderShareButtonMobileVersion={renderShareButtonMobileVersion}
          {...sampleReportStore}
          averageScoresForGraph={getAverageScoresForGraph}
          averageIntensities={averageIntensities}
          cloudWords={getCloudWords}
          flavorWheelOnlyFragrance={getFlavorWheelOnlyFragrance}
          flavorWheelOnlyFlavor={getFlavorWheelOnlyFlavor}
          flavorWheelGroups={getFlavorWheelGroups}
          allScoreDescriptors={allScoreDescriptors}
          sampleRecord={sampleRecord}
        />

        {isMobile && !isPrinting && !(window as any).isRNWebview && renderButtonMobileVersion}
      </Box>

      {renderEmailModal}

      <ModalWrapper open={openNps} maxWidth='sm'>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={6}>
            <Typography variant='h6' sx={{mb: 1}}>{t('report.listOfCuppers')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' sx={{mb: 1, color: colors.primary.main}}>{t('report.show')}</Typography>
          </Grid>
          {scores.map((sc, i) => {
            return (
              <>
                <Grid item xs={6}>
                  <Typography variant='h6' >{sc.cupperName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Switch
                    checked={select[sc.id]}
                    onChange={(event, checked) => {
                      onSelect(sc.id)
                      handleSwitch(checked, sc.id)
                    }}
                  />
                </Grid>
              </>
            )
          })}
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{mt: 2}}>
          <Button variant='text' onClick={() => {
            handleSwitchAll(true)
            onSelectAll()
          }} sx={{width: 200, mr: 1}}>
            {t('report.showAll')}
          </Button>
          <Button variant='outlined' onClick={() => {
            setOpenNps(false);
            setIsShowCupper(false)
          }} sx={{width: 200, mr: 1}}>
            {t('report.save')}
          </Button>
        </Box>
      </ModalWrapper>
    </Box>
    </Box>
  )
})

export default SampleReport
