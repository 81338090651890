/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:37
 * @modify date 2022-02-17 22:31:37
 */

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {useNavigate, useParams} from "react-router-dom"
import {Box, Button, Container, Stack, SvgIcon, Typography} from '@mui/material'
import {isEmpty} from "lodash";

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { HeaderTitle } from 'components'
import {IconSampleInfo} from "assets/images"
import {globalAction} from "utils";

import { SampleForm } from './components'
import { useStyles as wrapperStyles } from './sample-information.styles'
import { useStyles } from './components/sample-item/sample-item.styles'

export const MasterSample: React.FC = observer(() => {
  const wrapperClasses = wrapperStyles()
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const reviewSamplesLink = findRoute('reviewSamples')
  const {
    masterSampleStore: { masterSample, setMasterSample, saveMasterSample, getMasterSample },
    notificationStore
  } =  useStores()

  const [errors, setErrors] = useState<any>({})
  const [tempSample, setTempSample] = useState<any>({})

  const validation = (isDiscard = false) => {
    let errors = {};
    if (!masterSample.name && !isDiscard) {
      errors['name'] = {
        "name": [{ message: t('sample.error.sampleNameRequired') }]
      };
    }

    if (!masterSample.sampleType && !isDiscard) {
      errors['sampleType'] = {
        "sampleType": [{ message: t('sample.error.sampleTypeRequired') }]
      };
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onSaveSample = () => {
    setErrors({})
    if (!validation())  {
      window.scrollTo(0, 0);
      return
    }

    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await saveMasterSample()
        notificationStore.setNotification({
          severity: 'success',
          message: t(`sample.masterSample.success.${params.id === 'new' ? 'successfullyCreatedSample' : 'successfullyUpdatedSample'}`)
        })
        navigate(reviewSamplesLink)
      }
    })
  }

  React.useEffect(() => {
    setTempSample(setMasterSample({}))

    if(params.id !== 'new') {
      globalAction(notificationStore, {
        complexAction: true,
        action: async () => {
          const newMasterSample = await getMasterSample(params.id)
          setTempSample(newMasterSample)
        },
      })
    }
  }, [params.id])

  return (
    <>
      <HeaderTitle
        backUrl={reviewSamplesLink}
        breadcrumb={t("common.backTo", { menu: t("menu.reviewSamples") })}
        title={t('sample.masterSample.form.title')}
      />

      <Container className={wrapperClasses.containerWrapper}>
        <Container className={classes.tabPanelWrapper} sx={{p: 4, textAlign: 'left'}}>
          <Box className={classes.titleWrapper}>
            <Box className={classes.title}>
              <SvgIcon component={IconSampleInfo} color='primary' inheritViewBox sx={{mr: 1, p: 0.25}} />
              <Typography variant='h6'>{t('sample.sampleInfo')}</Typography>
            </Box>
          </Box>
          {params.id !== "new" &&
              <Box sx={{mb: 2}}>
                  <Typography variant='h4'>{isEmpty(masterSample.sampleUniqueNumber) ? '-' : masterSample.sampleUniqueNumber}</Typography>
              </Box>
          }
          <SampleForm
            {...masterSample}
            errors={errors}
            setValue={masterSample.setValue}
          />

          <Stack direction='row' spacing={2} className={classes.buttonWrapper}>
            <Button
              variant='contained'
              className='wide'
              onClick={onSaveSample}
            >
              {t('common.save')}
            </Button>
            <Button
              variant='outlined'
              className='wide'
              onClick={() => {
                if (!validation(true)) return
                setMasterSample(tempSample)
              }}
            >
              {t('common.discard')}
            </Button>
          </Stack>
        </Container>
      </Container>
    </>
  );
})

export default MasterSample
