import React, {useCallback, useEffect, useState} from 'react'
import {debounce} from "lodash"
import {useTranslation} from "react-i18next"
import {observer} from "mobx-react-lite"
import {Box, Button, Checkbox, selectClasses, Stack, Typography} from "@mui/material"
import {Cancel} from "@mui/icons-material"
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table"
import {Link} from "react-router-dom"

import {useStores} from "models"
import {FilterChip, FilterRequestTable} from "components"

import {useStyles} from "./contracts.styles"
import {SampleModal} from "./components"
import { countryName } from 'utils'

const PREFIX = 'contracts'

const columnHelper = createColumnHelper<any>()

export const Contracts: React.FC = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    contractStore: { page, newSamples, totalPage, getContracts, createSamples, setValue: setContractValue },
    notificationStore
  } = useStores()

  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedSamples, setSelectedSamples] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])
  const hasFilter = columnFilters.length > 0

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      enableColumnFilter: false,
      header: () => <Box />,
      cell: info =>
        <Box textAlign="left">
          <Checkbox
            checked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        </Box>,
	    meta: {
		    hideFilter: true,
		    style: { minWidth: '1px' }
	    }
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: t(`${PREFIX}.table.purchaseContract`),
      cell: info => <Link to={`/contracts/${info.renderValue()}/details`} >{info.renderValue()}</Link>,
    }),
    columnHelper.accessor('purchaseGrade', {
      id: 'purchaseGrade',
      header: t(`${PREFIX}.table.purchaseGrade`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('supplierName', {
      id: 'supplierName',
      header: t(`${PREFIX}.table.supplier`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: t(`${PREFIX}.table.country`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: t(`${PREFIX}.table.salesContract`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: t(`${PREFIX}.table.species`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('harvest', {
      id: 'harvest',
      header: t(`${PREFIX}.table.harvest`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('numberOfBag', {
      id: 'numberOfBag',
      header: t(`${PREFIX}.table.numberOfBag`),
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: t(`${PREFIX}.table.description`),
      cell: info => info.renderValue(),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, columnFilters },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
	  onColumnFiltersChange: (updaterOrValue) => {
		  setContractValue('page', 1)
		  setColumnFilters(updaterOrValue)
	  },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  })

  const clearFilter = () => {
    setRowSelection({})
    selectedSamples.length = 0
  }

  const filterLabel = (sample) => {
    return `${sample.purchaseContractReference}${sample.purchaseGrade ? `; ${sample.purchaseGrade}` : ''} ${sample.salesContractReference ? `; ${sample.salesContractReference}` : ''} ${sample.country ? `; ${sample.country}` : ''}`
  }

  const onRemoveSelectedSample = (sample) => {
    setSelectedSamples((prevSelected) =>
      prevSelected.filter((s) => parseInt(s.id) !== parseInt(sample.id))
    );
    setRowSelection((prevRowSelection) => {
      const updatedRowSelection = { ...prevRowSelection };
      delete updatedRowSelection[sample.id];
      return updatedRowSelection;
    });
  };

  const onCreateSample = () => {
    setOpenModal(true)
  }

  const onGetContracts = useCallback(
	  debounce(async (option?) => {
	    try {
	      notificationStore.setLoading(true)
	      const contracts = await getContracts(option)
	      setData(contracts)
	    } finally {
	      notificationStore.setLoading(false)
	    }
  }, 500), [])

  useEffect(() => {
    const ids = Object.keys(rowSelection).map(id => id)

    setSelectedSamples(selectedSamples.filter(item => ids.includes(item.id)))

    setSelectedSamples(prevSelected => [
      ...prevSelected,
      ...ids
        .map(id => data.find(item => item.id === id))
        .filter(item => item && !prevSelected.some(selected => selected.id === item.id)),
    ])
  }, [rowSelection])

  useEffect(() => {
    onGetContracts(columnFilters)
  }, [JSON.stringify(columnFilters), page])

  return (
    <Box px={4} py={2.5}>
      <Typography variant='h4'>
        {t(`${PREFIX}.title`)}
      </Typography>
      {selectedSamples.length > 0 && (
        <Stack direction="row" spacing={1} mb={2} useFlexGap flexWrap="wrap">
          {selectedSamples.map(s => (
            <FilterChip
              key={s.id}
              label={filterLabel(s)}
              onRemove={() => onRemoveSelectedSample(s)}
            />
          ))}

          <Button
            variant="text"
            size="small"
            color="error"
            onClick={clearFilter}
            sx={{px: 1}}
          >
            {t('common.clearFilter')}
          </Button>
        </Stack>
      )}
      <Stack direction="row" spacing={2} my={2} justifyContent="end">
        {hasFilter && (
          <Button
            variant="contained"
            sx={{borderRadius: '50px'}}
            startIcon={<Cancel />}
            onClick={() => setColumnFilters([])}
          >
            {t(`${PREFIX}.button.clearFilter`)}
          </Button>
        )}
        <Button
          variant="contained"
          disabled={selectedSamples.length === 0}
          onClick={onCreateSample}
        >
          {t(`${PREFIX}.button.createSample`)}
        </Button>
      </Stack>

      <Box className={classes.table}>
	      <FilterRequestTable
		      table={table}
		      page={page}
		      totalPage={totalPage}
		      onChangePage={(e, p) => setContractValue('page', p)}
	      />

        {/*<FilterTable table={table}/>*/}
      </Box>

      <SampleModal
        open={openModal}
        onClose={() => {
          clearFilter()

          setOpenModal(false)
        }}
        samples={selectedSamples}
        createSamples={createSamples}
        notificationStore={notificationStore}
      />
    </Box>
  )
})

export default Contracts
