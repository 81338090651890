import { flow } from "mobx-state-tree"

import { ApiResult } from "services/api"

export const withDashboardActions = (self: any) => ({
  actions: {
    getSearch: flow(function * (key: string) {
      try {
        const params = {
          'q[name]': key,
          'q[sample_order_number]': key,
          'q[purchase_contract_reference]': key,
          'q[sales_contract_reference]': key,
          'q[shipment_number]': key,
          'q[purchase_grade]': key,
          'q[sample_unique_number]': key,
        }

        const res: ApiResult = yield self.environment.searchApi.all(params)

        if (res.kind === "ok") {
          return res.data;
        }
   
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }
})
