/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:33:44
 * @modify date 2022-02-02 20:56:35
 */
import {
  AuthApi,
  UserApi,
  CompanyApi,
  DashboardApi,
  CupperSummaryApi,
  CupperApi,
  InvitationReminderApi,
  AdminPrivilegeApi,
  ActiveStatusApi,
  CuppingSessionsPermissionApi,
  CuppingSessionsLocationApi,
  CuppingSessionApi,
  SupplierApi,
  ProcessApi,
  ProducerApi,
  SampleApi,
  MasterSampleApi,
  SampleScoreApi,
  SampleReportApi,
  SampleImageApi,
  GreenGradingApi,
  SubscriptionApi,
  GuestInvitationApi,
  WebhookApi,
  NumberOfLicenseApi,
  ShareSampleApi,
  GuestScoreApi,
  FileApi,
  CollectiveReportApi,
  AbTestApi,
  RedeemCodeApi,
  DormantAccountApi,
  IndividualReportApi,
  GreenGradingTypeApi,
  GreenGradingSettingApi,
  ApproveSampleApi,
  SampleTransactionApi,
  SampleTransactionGuestApi,
  SampleAnalyticReportsApi,
  ContractApi,
  SampleTransactionItemGuestApi,
  AggregatesApi,
  CombinedReportApi,
  SearchApi,
} from "services/api"

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    this.authApi = new AuthApi()
    this.userApi = new UserApi()
    this.companyApi = new CompanyApi()
    this.dashboardApi = new DashboardApi()
    this.cupperSummaryApi = new CupperSummaryApi()
    this.cupperApi = new CupperApi()
    this.invitationReminderApi = new InvitationReminderApi()
    this.adminPrivilegeApi = new AdminPrivilegeApi()
    this.activeStatusApi = new ActiveStatusApi()
    this.cuppingSessionsPermissionApi = new CuppingSessionsPermissionApi()
    this.cuppingSessionsLocationApi = new CuppingSessionsLocationApi()
    this.cuppingSessionApi = new CuppingSessionApi()
    this.supplierApi = new SupplierApi()
    this.processApi = new ProcessApi()
    this.producerApi = new ProducerApi()
    this.sampleApi = new SampleApi()
    this.masterSampleApi = new MasterSampleApi()
    this.sampleScoreApi = new SampleScoreApi()
    this.sampleReportApi = new SampleReportApi()
    this.sampleImageApi = new SampleImageApi()
    this.greenGradingApi = new GreenGradingApi()
    this.subscriptionApi = new SubscriptionApi()
    this.guestInvitationApi = new GuestInvitationApi()
    this.webhookApi = new WebhookApi()
    this.numberOfLicenseApi = new NumberOfLicenseApi()
    this.shareSampleApi = new ShareSampleApi()
    this.guestScoreApi = new GuestScoreApi()
    this.fileApi = new FileApi()
    this.collectiveReportApi = new CollectiveReportApi()
    this.abTestApi = new AbTestApi()
    this.redeemCodeApi = new RedeemCodeApi()
    this.dormantAccountApi = new DormantAccountApi()
    this.individualReportApi = new IndividualReportApi()
    this.greenGradingTypeApi = new GreenGradingTypeApi()
    this.greenGradingSettingApi = new GreenGradingSettingApi()
    this.approveSampleApi = new ApproveSampleApi()
    this.sampleTransactionApi = new SampleTransactionApi()
    this.sampleTransactionGuestApi = new SampleTransactionGuestApi()
    this.sampleAnalyticReportsApi = new SampleAnalyticReportsApi()
    this.aggregatesApi = new AggregatesApi()
    this.contractApi = new ContractApi()
    this.sampleTransactionItemGuestApi = new SampleTransactionItemGuestApi()
    this.combinedReportApi = new CombinedReportApi()
    this.searchApi = new SearchApi()
  }

  async setup() {
    await this.authApi.setup()
    await this.userApi.setup()
    await this.companyApi.setup()
    await this.dashboardApi.setup()
    await this.cupperSummaryApi.setup()
    await this.cupperApi.setup()
    await this.invitationReminderApi.setup()
    await this.adminPrivilegeApi.setup()
    await this.activeStatusApi.setup()
    await this.cuppingSessionsPermissionApi.setup()
    await this.cuppingSessionsLocationApi.setup()
    await this.cuppingSessionApi.setup()
    await this.supplierApi.setup()
    await this.processApi.setup()
    await this.producerApi.setup()
    await this.sampleApi.setup()
    await this.masterSampleApi.setup()
    await this.sampleScoreApi.setup()
    await this.sampleReportApi.setup()
    await this.sampleImageApi.setup()
    await this.greenGradingApi.setup()
    await this.subscriptionApi.setup()
    await this.guestInvitationApi.setup()
    await this.webhookApi.setup()
    await this.numberOfLicenseApi.setup()
    await this.shareSampleApi.setup()
    await this.guestScoreApi.setup()
    await this.fileApi.setup()
    await this.collectiveReportApi.setup()
    await this.abTestApi.setup()
    await this.redeemCodeApi.setup()
    await this.dormantAccountApi.setup()
    await this.individualReportApi.setup()
    await this.greenGradingTypeApi.setup()
    await this.greenGradingSettingApi.setup()
    await this.approveSampleApi.setup()
    await this.sampleTransactionApi.setup()
    await this.sampleTransactionGuestApi.setup()
    await this.sampleAnalyticReportsApi.setup()
    await this.aggregatesApi.setup()
    await this.contractApi.setup()
    await this.sampleTransactionItemGuestApi.setup()
    await this.combinedReportApi.setup()
    await this.searchApi.setup()
  }

  /**
   * Authentication api.
   */
  userApi: AuthApi

  /**
   * User api.
   */
  authApi: UserApi

  /**
   * Company api.
   */
  companyApi: CompanyApi

  /**
   *  Dashboard api.
   */
  dashboardApi: DashboardApi

  /**
   *  Cupper summary api.
   */
  cupperSummaryApi: CupperSummaryApi

  /**
   *  Cupper summary api.
   */
  cupperApi: CupperApi

  /**
   *  Invitation reminder api.
   */
  invitationReminderApi: InvitationReminderApi

  /**
   *  Invitation reminder api.
   */
  adminPrivilegeApi: AdminPrivilegeApi

  /**
   *  Invitation reminder api.
   */
  activeStatusApi: ActiveStatusApi

  /**
   *  Invitation reminder api.
   */
  cuppingSessionsPermissionApi: CuppingSessionsPermissionApi

  /**
   *  Invitation reminder api.
   */
  cuppingSessionsLocationApi: CuppingSessionsLocationApi

  /**
   *  Invitation reminder api.
   */
  cuppingSessionApi: CuppingSessionApi

  /**
   *  Supplier api.
   */
  supplierApi: SupplierApi

  /**
   *  Process api.
   */
  processApi: ProcessApi

  /**
   *  Process api.
   */
  producerApi: ProducerApi

  /**
   *  Sample api.
   */
  sampleApi: SampleApi

  /**
   *  Mater sample api
   */
  masterSampleApi: MasterSampleApi

  /**
   *  Sample score api.
   */
  sampleScoreApi: SampleScoreApi

  /**
   *  Sample report api.
   */
  sampleReportApi: SampleReportApi

  /**
   *  Sample image api.
   */
  sampleImageApi: SampleImageApi

  /**
   *  Green grading api.
   */
  greenGradingApi: GreenGradingApi

  /**
   *  Subscription api.
   */
  subscriptionApi: SubscriptionApi

  /**
   *  Guest invitation api.
   */
  guestInvitationApi: GuestInvitationApi

  /**
   *  Webhook api.
   */
  webhookApi: WebhookApi

  /**
   *  Webhook api.
   */
  numberOfLicenseApi: NumberOfLicenseApi

  /**
   *  Share sample api.
   */
  shareSampleApi: ShareSampleApi

  /**
   *  Guest sample score api.
   */
  guestScoreApi: GuestScoreApi

  /**
   *  Upload file api.
   */
  fileApi: FileApi

  /**
   *  collective report api.
   */
  collectiveReportApi: CollectiveReportApi

  /**
   * A/B test api.
   */
  abTestApi: AbTestApi

  /**
   * Redeem code api.
   */
  redeemCodeApi: RedeemCodeApi

  /**
   * Dormant account api.
   */
  dormantAccountApi: DormantAccountApi

  /**
   * Individual report api
   */
  individualReportApi: IndividualReportApi

  /**
   * Green grading type api
   */
  greenGradingTypeApi: GreenGradingTypeApi

  /**
   * Green grading setting api
   */
  greenGradingSettingApi: GreenGradingSettingApi

  /**
   * Approve sample api
   */
  approveSampleApi: ApproveSampleApi

  /**
   * Sample shipment and order
   */
  sampleTransactionApi: SampleTransactionApi

  /**
   * Guest sample shipment and order
   */
  sampleTransactionGuestApi: SampleTransactionGuestApi

  /**
   * Guest sample transaction item
   */
  sampleTransactionItemGuestApi: SampleTransactionItemGuestApi

  /**
   * Analytic Report
   */
  sampleAnalyticReportsApi: SampleAnalyticReportsApi

  /**
   * Cupping result aggregates
   */
  aggregatesApi: AggregatesApi

  /**
   * Contract api
   */
  contractApi: ContractApi

  /**
   * Combined report api
   */
  combinedReportApi: CombinedReportApi

   /**
   * Global search api
   */
   searchApi: SearchApi
}
