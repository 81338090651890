import { toNumber } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import packageInfo from '../../package.json'

const API_URL = process.env.REACT_APP_API_URL
const API_VERSION = 2
const API_TIMEOUT = toNumber(process.env.REACT_APP_API_TIMEOUT)
const UPLOAD_TIMEOUT = toNumber(process.env.REACT_APP_UPLOAD_TIMEOUT)
const JWT_STORAGE_KEY = 'JWT_TOKEN'
const REPORT_CONFIGURATION_STORAGE_KEY = 'REPORT_CONFIGURATION'
const HEADER_SELECTOR_SAMPLE_ORDER_STORAGE_KEY = 'HEADER_SELECTOR_SAMPLE_ORDER'
const HEADER_SELECTOR_SAMPLE_STORAGE_KEY = 'HEADER_SELECTOR_SAMPLE'
const REFRESH_TOKEN_STORAGE_KEY = 'REFRESH_TOKEN'
const TIME_TO_RELOGIN = 2
const LANGUAGE_STORAGE_KEY = "LANGUAGE"
const ROOT_STATE_STORAGE_KEY = 'ROOT'
const DATA_PER_PAGE = 20
const DEBOUNCE_TIME = 1000
const INDIVIDUAL_PLAN_ID = process.env.REACT_APP_INDIVIDUAL_PLAN_ID
const ENTERPRISE_PLAN_ID = process.env.REACT_APP_ENTERPRISE_PLAN_ID
const ENTERPRISE_PLUS_PLAN_ID = process.env.REACT_APP_ENTERPRISE_PLUS_PLAN_ID
const INDIVIDUAL_ANNUALLY_PLAN_ID = process.env.REACT_APP_INDIVIDUAL_ANNUALLY_PLAN_ID
const ENTERPRISE_ANNUALLY_PLAN_ID = process.env.REACT_APP_ENTERPRISE_ANNUALLY_PLAN_ID
const ENTERPRISE_PLUS_ANNUALLY_PLAN_ID = process.env.REACT_APP_ENTERPRISE_PLUS_ANNUALLY_PLAN_ID
const BETA_PLAN_ID = process.env.REACT_APP_BETA_PLAN_ID
const DORMANT_PLAN_ID = process.env.REACT_APP_DORMANT_PLAN_ID
const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)
const COMPLAIN_EMAIL_ADDRESS = process.env.REACT_APP_COMPLAIN_EMAIL_ADDRESS
const VIDEO_TUTORIAL_URL = process.env.REACT_APP_VIDEO_TUTORIAL_URL
const REACT_APP_FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_URL
const BLOG_URL = process.env.REACT_APP_BLOG_URL
const GUIDE_URL = process.env.REACT_APP_GUIDE_URL
const SAMPLE_STORAGE_KEY = 'SAMPLE'
const CUPPED_STORAGE_KEY = 'CUPPED'
const WEB_URL = process.env.REACT_APP_WEB_URL
const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL
const TRIAL_STATUS_NAME = 'trialing'
const CANCEL_STATUS_NAME = 'canceled'
const UNPAID_STATUS_NAME = 'unpaid'
const PAST_DUE_STATUS_NAME = 'past_due'
const RECAPTCHA_API_KEY = process.env.REACT_APP_RECAPTCHA_API_KEY!
const DEFAULT_ADMIN = 'default-admin'
const DEFAULT_COMPANY = 'default-company'
const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY!
const AB_TEST_MIXPANEL_API_KEY = process.env.REACT_APP_AB_TEST_MIXPANEL_API_KEY!
const ANONYMOUS_NAME = 'anonymous'
const CUPPING_SESSION_OWNER_TITLE = 'cuppingSessionOwner'
const MODULE = process.env.REACT_APP_MODULE
const APP_VERSION = packageInfo.version
const DEFAULT_SAMPLE_LOCATION = 'Sucafina NA'
const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY!
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER!
const MAINTENANCE_BANNER_ON = process.env.REACT_APP_MAINTENANCE_BANNER_ON === 'true'
const MAINTENANCE_BANNER_DATE = process.env.REACT_APP_MAINTENANCE_BANNER_DATE || ''
const MAINTENANCE_BANNER_START_TIME = process.env.REACT_APP_MAINENANCE_BANNER_START_TIME || ''
const MAINTENANCE_BANNER_END_TIME = process.env.REACT_APP_MAINENANCE_BANNER_END_TIME || ''
const PRODUCTION_MODE = process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test'

const ROLLBAR = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
      environment: process.env.NODE_ENV
  }
}

export {
  API_URL,
  API_VERSION,
  API_TIMEOUT,
  UPLOAD_TIMEOUT,
  JWT_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  TIME_TO_RELOGIN,
  LANGUAGE_STORAGE_KEY,
  ROOT_STATE_STORAGE_KEY,
  DATA_PER_PAGE ,
  INDIVIDUAL_PLAN_ID,
  ENTERPRISE_PLAN_ID,
  ENTERPRISE_PLUS_PLAN_ID,
  INDIVIDUAL_ANNUALLY_PLAN_ID,
  ENTERPRISE_ANNUALLY_PLAN_ID,
  ENTERPRISE_PLUS_ANNUALLY_PLAN_ID,
  BETA_PLAN_ID,
  DORMANT_PLAN_ID,
  STRIPE_PROMISE,
  DEBOUNCE_TIME,
  COMPLAIN_EMAIL_ADDRESS,
  VIDEO_TUTORIAL_URL,
  REACT_APP_FEEDBACK_URL,
  ROLLBAR,
  SAMPLE_STORAGE_KEY,
  WEB_URL,
  WEB_APP_URL,
  TRIAL_STATUS_NAME,
  CANCEL_STATUS_NAME,
  UNPAID_STATUS_NAME,
  PAST_DUE_STATUS_NAME,
  RECAPTCHA_API_KEY,
  DEFAULT_ADMIN,
  DEFAULT_COMPANY,
  CUPPED_STORAGE_KEY,
  MIXPANEL_API_KEY,
  AB_TEST_MIXPANEL_API_KEY,
  BLOG_URL,
  GUIDE_URL,
  ANONYMOUS_NAME,
  CUPPING_SESSION_OWNER_TITLE,
  MODULE,
  APP_VERSION,
  DEFAULT_SAMPLE_LOCATION,
  PUSHER_KEY,
  PUSHER_CLUSTER,
  MAINTENANCE_BANNER_ON,
  MAINTENANCE_BANNER_DATE,
  MAINTENANCE_BANNER_START_TIME,
  MAINTENANCE_BANNER_END_TIME,
  REPORT_CONFIGURATION_STORAGE_KEY,
  PRODUCTION_MODE,
  HEADER_SELECTOR_SAMPLE_ORDER_STORAGE_KEY,
  HEADER_SELECTOR_SAMPLE_STORAGE_KEY
}
