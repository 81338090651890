import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material"

import { ModalWrapper } from "components"
import { CuppingResults, GreenGradings } from "../index"
import { ReportConfigurationModal } from "components/report-configuration-modal/report-configuration-modal"

export const GenerateReport = (props) => {
  const { t } = useTranslation()
  const [selectedCuppingResult, setSelectedCuppingResult] = useState<Set<string>>(new Set());
  const [selectedGreenGrading, setSelectedGreenGrading] = useState('');
  const [isOpenReportConfiguration, setOpenReportConfiguration] = useState<boolean>(false);
  const [navigateUrl, setNavigateUrl] = useState<string>('');

  const getSelectedCuppingResultIds = () => {
    return [...selectedCuppingResult];
  }

  const onChangeSelectedCuppingResult = (cuppingId: string | number) => {
    setSelectedCuppingResult((prev) => {
      const updatedSet = new Set(prev);
      const cId = cuppingId.toString();
      if (updatedSet.has(cId)) {
        updatedSet.delete(cId);
      } else {
        updatedSet.add(cId);
      }
      return updatedSet;
    });
  };

  const onGenerate = () => {
    const sampleScoreIds = getSelectedCuppingResultIds().join(',');
    setNavigateUrl(`/combined-report?sample_score_id=${sampleScoreIds}&green_grading_id=${selectedGreenGrading}`);
    handleOpenReportConfigurationModal();
  }

  const handleOpenReportConfigurationModal = () => {
    setOpenReportConfiguration(true);
  }

  const handleCloseReportConfigurationModal = () => {
    setOpenReportConfiguration(false);
  }

  return (
    <>
      <ModalWrapper
        open={props.open}
        containerProps={{
          sx: { '& .MuiAccordion-root': { boxShadow: 'none', '&:before': { display: 'none' } } }
        }}
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box>
              <Typography variant="h5">{t('generateReport.cuppingResult.title')}</Typography>
              <Typography variant="body2">{t('generateReport.cuppingResult.description')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <CuppingResults isReport selectedCuppingResult={selectedCuppingResult} onChangeSelectedCuppingResult={onChangeSelectedCuppingResult} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box>
              <Typography variant="h5">{t('generateReport.greenGrading.title')}</Typography>
              <Typography variant="body2">{t('generateReport.greenGrading.description')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <GreenGradings isReport selectedGreenGrading={selectedGreenGrading} setSelectedGreenGrading={setSelectedGreenGrading} />
          </AccordionDetails>
        </Accordion>

        <Stack direction="row" justifyContent="end" spacing={2} mt={4}>
          <Button
            size="small"
            variant="contained"
            onClick={onGenerate}
          >
            {t('generateReport.generate')}
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={props.onClose}
          >
            {t('common.cancel')}
          </Button>
        </Stack>
      </ModalWrapper>
      <ReportConfigurationModal
        isOpen={isOpenReportConfiguration}
        type="combined"
        navigateUrl={navigateUrl}
        onClose={handleCloseReportConfigurationModal}
      />
    </>

  )
}
