import { range } from "lodash"
import {
  IconExplodingHead,
  IconFaceWithTongue,
  IconGrinningFace, IconPersonShrugging,
  IconPileOfPoo, IconSleepingFace,
  IconSmilingFace, IconTiredFace,
  IconZanyFace
} from 'assets/images'
import i18n from "../i18n/i18n";

export const specieses = [
  'arabica',
  'robusta',
  'liberica',
  'blend',
  'unknown'
]

export const sampleTypes = [
  'offer',
  'pre_shipment',
  'landed',
  'type',
  'stock_lot',
  'evaluation',
  'auction',
  'pre_auction',
  'spot',
  'other'
]

export const statuses = [
  'approved',
  'rejected'
]

export const grades = [
  'premium',
  'speciality',
  'exchange',
  'standard',
  'off_grade'
]

export const colors = [
  'blue_green',
  'bluish_green',
  'green',
  'greenish',
  'yellow_green',
  'pale_yellow',
  'yellowish',
  'brownish'
]

export const counterpartyTypes = [
  "trader",
  "logistic_team",
  "client",
  "origin_lab",
  "courrier",
  "warehouse"
]

export const fulfillmentTypes = [
  "local",
  "third_party"
]

export const fulfillmentStatus = [
  "unfulfilled",
  "requested",
  "fulfilled",
  "cancelled"
]

export const subOrderStatus = [
  "unfulfilled",
  "pending",
  "in_progress",
  "fulfilled"
]

export const sortSampleShipments = [
  {key: 'shipment_number', sort: 'default', label: 'shipment.sort.shipment'},
  {key: 'customer', sort: 'default', label: 'shipment.sort.customer'},
  {key: 'of_samples', sort: 'default', label: 'shipment.sort.of_samples'},
  {key: 'created_at', sort: 'default', label: 'shipment.sort.created_at'},
  {key: 'fulfillment', sort: 'default', label: 'shipment.sort.type'},
  {key: 'sample_location', sort: 'default', label: 'shipment.sort.sample_location'},
  {key: 'fulfillment_status', sort: 'default', label: 'shipment.sort.fulfillment_status'},
  {key: 'country', sort: 'default', label: 'shipment.sort.country'},
]

export const sortSampleOrders = [
  {key: 'sample_order_number', sort: 'default', label: 'order.sort.sample_order'},
  {key: 'customer', sort: 'default', label: 'order.sort.customer'},
  {key: 'created_at', sort: 'default', label: 'order.table.requestedOn'},
  {key: 'fulfillment_status', sort: 'default', label: 'order.sort.fulfillment_status'},
  {key: 'country', sort: 'default', label: 'order.sort.country'},
  { key: 'recipient', sort: 'default', label: 'order.table.recipient'},
  { key: 's_purchase_contract_reference', sort: 'default', label: 'order.table.purchaseContract'},
  { key: 's_sales_contract_reference', sort: 'default', label: 'order.table.salesContract'},
  { key: 'city', sort: 'default', label: 'order.table.city'},
  { key: 'state', sort: 'default', label: 'order.table.state'},
  { key: 'street_address', sort: 'default', label: 'order.table.streetAddress'},
  { key: 'street_address2', sort: 'default', label: 'order.table.streetAddress2'},
  { key: 's_warehouse_reference', sort: 'default', label: 'order.table.warehouses'},
  { key: 'number_of_samples', sort: 'default', label: 'order.table.ofSamples'},
  { key: 'fulfillment', sort: 'default', label: 'order.table.fulfillment'},
  { key: 'zip_code', sort: 'default', label: 'order.table.zipCode'},
  { key: 'is_urgent_delivery', sort: 'default', label: 'order.table.isUrgentDelivery'},
  { key: 'requested_by_email', sort: 'default', label: 'order.table.requestedBy'},
  { key: 'estimated_time_arrival', sort: 'default', label: 'order.table.estimatedTimeArrival'},
  { key: 'fulfilled_on', sort: 'default', label: 'order.table.fulfilledOn'},
]

export const filterSampleShipments = [
  { key: 'third_party_email_blank', group: 'type', items: ['third_party', 'local'], value: '' },
  { key: 'fulfillment_status', group: 'fulfillment_status', items: ['fulfilled', 'unfulfilled', 'cancelled'], value: '' },
  { key: 'created_at', group: 'created_at', items: [], value: '' },
  { key: 'customer', group: 'customer', items: [], value: '' },
  { key: 'country', group: 'country', items: [], value: '' },
  { key: 'purchase_contract_reference', group: 'purchase_contract_reference', items: [], value: '' },
  { key: 'sales_contract_reference', group: 'sales_contract_reference', items: [], value: '' },
  { key: 'shipment_number', group: 'shipment_number', items: [], value: '' },
]

export const filterSampleOrders = [
  { key: 'fulfillment_status', group: 'fulfillment_status', items: ['fulfilled', 'unfulfilled', 'cancelled', 'in_progress'], value: '' },
  { key: 'customer', group: 'customer', items: [], value: '' },
  { key: 'country', group: 'country', items: [], value: '' },
  { key: 'purchase_contract_reference', group: 'purchase_contract_reference', items: [], value: '' },
  { key: 'sales_contract_reference', group: 'sales_contract_reference', items: [], value: '' },
  { key: 'sample_order_number', group: 'sample_order_number', items: [], value: '' },
  { key: 'warehouse_reference', group: 'warehouse_reference', items: [], value: '' },
]

export const sortSamples = [
  {key: 'sample_unique_number', sort: 'default', label: 'sample.samples.id'},
  {key: 'name', sort: 'default', label: 'sample.samples.name'},
  {key: 'created_at', sort: 'default', label: 'sample.samples.createdOn'},
  {key: 'sample_type', sort: 'default', label: 'sample.samples.type'},
  {key: 'country_code', sort: 'default', label: 'sample.samples.origin'},
  {key: 'process_name_insensitive', sort: 'default', label: 'sample.samples.process'},
  {key: 'purchase_contract_reference', sort: 'default', label: 'sample.samples.purchaseContract'},
  {key: 'sales_contract_reference', sort: 'default', label: 'sample.samples.salesContract'},
  {key: 'reference_number', sort: 'default', label: 'sample.referenceNumber'},
  {key: 'cached_average_score', sort: 'default', label: 'sample.averageScore'},
  { key: 'purchase_grade', sort: 'default', label: 'sample.purchaseGrade'},
  { key: 'grade', sort: 'default', label: 'sample.sampleGrade'},
  { key: 'supplier_name_insensitive', sort: 'default', label: 'sample.supplier'},
  { key: 'producer_name_insensitive', sort: 'default', label: 'sample.producerName'},
  { key: 'strategy', sort: 'default', label: 'sample.strategy'},
  { key: 'customer', sort: 'default', label: 'sample.customer'},
  { key: 'customer_code', sort: 'default', label: 'sample.customerCode'},
  { key: 'received_on', sort: 'default', label: 'sample.receivedOn'},
  { key: 'date_dispatch', sort: 'default', label: 'sample.dateDispatch'},
  { key: 'date_arrival', sort: 'default', label: 'sample.dateArrival'},
  { key: 'date_results', sort: 'default', label: 'sample.dateResults'},
  { key: 'harvest', sort: 'default', label: 'sample.harvest'},
  { key: 'external_identification', sort: 'default', label: 'sample.externalIdentification'},
  { key: 'sample_reference', sort: 'default', label: 'sample.sampleReference'},
  { key: 'species', sort: 'default', label: 'sample.samples.species'},
  { key: 'varietals', sort: 'default', label: 'sample.varietalsTags'},
  { key: 'certification', sort: 'default', label: 'sample.certification'},
  { key: 'cupping_protocol', sort: 'default', label: 'sample.cuppingProtocol'},
  { key: 'received_weight', sort: 'default', label: 'sample.receivedWeight'},
  { key: 'crop_year', sort: 'default', label: 'sample.cropYear'},
  { key: 'number_of_bag_decimal', sort: 'default', label: 'sample.numberOfBags'},
  { key: 'bag_weight', sort: 'default', label: 'sample.bagWeight'},
  { key: 'moisture', sort: 'default', label: 'sample.moisture'},
  { key: 'water_activity', sort: 'default', label: 'sample.waterActivity'},
  { key: 'density', sort: 'default', label: 'sample.density'},
  { key: 'temperature', sort: 'default', label: 'sample.temperature'},
  { key: 'mass', sort: 'default', label: 'sample.mass'},
  { key: 'volume', sort: 'default', label: 'sample.volume'},
  { key: 'warehouse_reference', sort: 'default', label: 'sample.warehouseReference'},
  { key: 'shipment_date', sort: 'default', label: 'sample.shipmentDate'},
  { key: 'cargo_number', sort: 'default', label: 'sample.cargoSeel'},
  { key: 'container_number', sort: 'default', label: 'sample.containerNumber'},
  { key: 'lot_number_or_ico_marks', label: 'sample.lotNumberOrIcoMarks'},
  { key: 'courier_name', sort: 'default', label: 'sample.courier'},
  { key: 'tracking_number', sort: 'default', label: 'sample.trackingNumber'},
  {key: 'sample_location', sort: 'default', label: 'sample.sampleLocation'},
]

export const headerSelectorSampleOrders = [
  { key: 'sampleOrderNumber', label: 'order.table.sampleOrder',value: true },
  { key: 'customer', label: 'order.table.customer',value: true },
  { key: 'createdAt', label: 'order.table.requestedOn',value: true },
  { key: 'recipient', label: 'order.table.recipient',value: true },
  { key: 'country', label: 'order.table.country',value: true },
  { key: 'purchaseContractReference', label: 'order.table.purchaseContract',value: true },
  { key: 'salesContractReference', label: 'order.table.salesContract',value: true },
  { key: 'city', label: 'order.table.city',value: true },
  { key: 'state', label: 'order.table.state',value: true },
  { key: 'fulfillmentStatus', label: 'order.table.status',value: true },
  { key: 'streetAddress', label: 'order.table.streetAddress',value: true },
  { key: 'streetAddress2', label: 'order.table.streetAddress2',value: true },
  { key: 'warehouses', label: 'order.table.warehouses',value: true },
  { key: 'numberOfSamples', label: 'order.table.ofSamples',value: true },
  { key: 'fulfillment', label: 'order.table.fulfillment',value: true },
  { key: 'zipCode', label: 'order.table.zipCode',value: true },
  { key: 'trackingNumber', label: 'order.table.trackingNumber',value: true },
  { key: 'courierName', label: 'order.table.courierName',value: true },
  { key: 'thirdPartyName', label: 'order.table.thirdPartyName',value: true },
  { key: 'thirdPartyEmail', label: 'order.table.thirdPartyEmail',value: true },
  { key: 'isUrgentDelivery', label: 'order.table.isUrgentDelivery',value: true },
  { key: 'requestedByEmail', label: 'order.table.requestedBy',value: true },
  { key: 'fulfilledOn', label: 'order.table.fulfilledOn',value: true },
  { key: 'estimatedTimeArrival', label: 'order.table.estimatedTimeArrival',value: true },
  { key: 'uniqueToken', label: 'order.table.action',value: true },
  { key: 'expand', label: '',value: true },
]

export const headerSelectorSamples = [
  { key: 'checkboxSampleUniqueNumber', label: '',value: true },
  { key: 'sampleUniqueNumber', label: 'sample.samples.id',value: true },
  { key: 'name', label: 'sample.samples.name',value: true },
  { key: 'purchaseGrade', label: 'sample.purchaseGrade',value: true },
  { key: 'createdAt', label: 'sample.samples.createdOn',value: true },
  { key: 'country', label: 'sample.samples.origin',value: true },
  { key: 'grade', label: 'sample.sampleGrade',value: true },
  { key: 'sampleType', label: 'sample.samples.type',value: true },
  { key: 'supplierName', label: 'sample.supplier',value: true },
  { key: 'producerName', label: 'sample.producerName',value: true },
  { key: 'purchaseContractReference', label: 'sample.samples.purchaseContract',value: true },
  { key: 'salesContractReference', label: 'sample.samples.salesContract',value: true },
  { key: 'strategy', label: 'sample.strategy',value: true },
  { key: 'customer', label: 'sample.customer',value: true },
  { key: 'customerCode', label: 'sample.customerCode',value: true },
  { key: 'receivedOn', label: 'sample.receivedOn',value: true },
  { key: 'dateDispatch', label: 'sample.dateDispatch',value: true },
  { key: 'dateArrival', label: 'sample.dateArrival',value: true },
  { key: 'dateResults', label: 'sample.dateResults',value: true },
  { key: 'harvest', label: 'sample.harvest',value: true },
  { key: 'externalIdentification', label: 'sample.externalIdentification',value: true },
  { key: 'referenceNumber', label: 'sample.referenceNumber',value: true },
  { key: 'sampleReference', label: 'sample.sampleReference',value: true },
  { key: 'description', label: 'sample.description',value: true },
  { key: 'species', label: 'sample.samples.species',value: true },
  { key: 'varietalsTags', label: 'sample.varietalsTags',value: true },
  { key: 'processName', label: 'sample.samples.process',value: true },
  { key: 'certification', label: 'sample.certification',value: true },
  { key: 'cuppingProtocol', label: 'sample.cuppingProtocol',value: true },
  { key: 'receivedWeight', label: 'sample.receivedWeight',value: true },
  { key: 'cropYear', label: 'sample.cropYear',value: true },
  { key: 'numberOfBag', label: 'sample.numberOfBags',value: true },
  { key: 'bagWeight', label: 'sample.bagWeight',value: true },
  { key: 'moisture', label: 'sample.moisture',value: true },
  { key: 'waterActivity', label: 'sample.waterActivity',value: true },
  { key: 'density', label: 'sample.density',value: true },
  { key: 'temperature', label: 'sample.temperature',value: true },
  { key: 'mass', label: 'sample.mass',value: true },
  { key: 'volume', label: 'sample.volume',value: true },
  { key: 'warehouseReference', label: 'sample.warehouseReference',value: true },
  { key: 'sampleLocation', label: 'sample.sampleLocation',value: true },
  { key: 'shipmentDate', label: 'sample.shipmentDate',value: true },
  { key: 'cargoNumber', label: 'sample.cargoSeel',value: true },
  { key: 'containerNumber', label: 'sample.containerNumber',value: true },
  { key: 'lotNumberOrIcoMarks', label: 'sample.lotNumberOrIcoMarks',value: true },
  { key: 'courierName', label: 'sample.courier',value: true },
  { key: 'trackingNumber', label: 'sample.trackingNumber',value: true },
  { key: 'notesAndRemarks', label: 'sample.notesAndRemarks',value: true },
  { key: 'averageScore', label: 'sample.samples.averageScore',value: true },
  { key: 'action', label: 'sample.samples.action',value: true },
]

export const defects = [
  { id: "full_black", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "full_sour", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "fungus_damage", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "foreign_matter", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "dried_cherry_pods", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "severe_insect_damage", min: null, max: 5, equivalence: 1, type: "primary" },
  { id: "partial_black", min: null, max: 3, equivalence: 1, type: "secondary" },
  { id: "partial_sour", min: null, max: 3, equivalence: 1, type: "secondary" },
  { id: "slight_insect_damage", min: null, max: 10, equivalence: 2, type: "secondary" },
  { id: "broken_chipped_cut", min: 2, max: 5, equivalence: 1, type: "secondary" },
  { id: "immature_bean", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "withered_bean", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "shell", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "floater", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "parchment", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "hull_husk", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "quakers", min: null, max: 5, equivalence: 1, type: "secondary" }
]

export const printLabelSize = [
  {key: 'letter', width: '816', height: '1056', label: 'paper.letter', size: '279mm 215mm'},
  {key: 'dk', width: '253', height: '231', label: 'paper.dk', size: '67mm 61mm'},
  {key: 'dymo', width: '204', height: '382', label: 'paper.dymo', size: '54mm 101mm'},
  {key: 'sucafina_na', width: '200', height: '100', label: 'paper.sucafina_na', size: '200mm 100mm'},
]

export const viewSampleOrders = [
  {key: 'table', label: 'Table'},
  {key: 'kanban', label: 'Kanban'},
]


export const courierNames = [
  'Aramark',
  'DHL',
  'EMS',
  'FedEx',
  'USPS',
  'AUSPOST',
  'UC',
  'SF Express',
  'Royal Mail'
]

export const defectKeys = ['primary', 'secondary']

export const scaSpecialtyDefects = defects

export const descriptorSetDefault = 'arabica'
export const descriptorSets = [
  { value: 'tastify_arabica', label: 'sample.tastifyArabica' },
  { value: 'sca_arabica', label: 'sample.scaArabica' }
]

export const cuppingProtocols = [
  'arabica',
  'robusta',
  'cup_of_excellence',
  'sca_affective',
  'sca_descriptive',
  'sca',
  'sucafina_tasting',
  'commercial',
  'soluble',
	'simplified_common'
]

export const dateCategory = [
  "created_date",
  "cupping_date",
  "green_grading_date",
  "shipment_date"
]

export const listYears = range((new Date()).getFullYear(), 2010)

export const defaultTotalScoreCuppingProtocols = [
  { key: 'arabica', totalScore: 165 },
  { key: 'robusta', totalScore: 160 },
  { key: 'cup_of_excellence', totalScore: 156 },
  { key: 'sca_affective', totalScore: 119 },
  { key: 'sca', totalScore: 119 },
  { key: 'sucafina_tasting', totalScore: 0 },
  { key: 'commercial', totalScore: 4 },
  { key: 'soluble', totalScore: 0 },
  { key: 'simplified_common', totalScore: 0 }
]

export const sampleIdStructures = [
  { value: 'number', label: 'cuppingSession.number' },
  { value: 'three_digits', label: 'cuppingSession.digit' },
  { value: 'letter', label: 'cuppingSession.letter' }
]

export const greenGradingTypes = [
  'sca_specialty',
  'gca',
  'brazil',
  'peru',
  'colombia',
  'vietnam_robusta',
  'nicaragua',
  'png',
  'drugar',
  'honduras'
]

export const cuppingSessionFilters = [
  { key: '', label: 'cuppingSession.noFilter' },
  { key: 'incomplete_sessions', label: 'cuppingSession.incompleteSessions' },
  { key: 'upcoming_sessions', label: 'cuppingSession.upcomingSessions' },
  { key: 'completed_sessions', label: 'cuppingSession.completedSessions' }
]

export const coffeeTypes = [
  'cherry',
  'parchment',
  'green_beans',
  'roasted_beans',
  'soluble',
  'others'
]

export const simplifiedCommonSelections = [
	'Phenol',
	'Very High',
	'High',
	'Medium/High',
	'Medium',
	'Medium/Low',
	'Mild/High',
	'Mild',
	'Mild/Low',
	'Low/Mild',
	'Low',
]

export const simplifiedCommonExpectations = [
	'Above',
	'Just Above',
	'Meets',
	'Just Meets',
	'Just Below',
	'Below',
	'Poor',
	'Needs to be Green Graded'
]

export const simplifiedCommonSampleResults = [
	'Approved',
	'Pending',
	'Pending Trader',
	'Pending Client',
	'Pending Green Grading',
	'Claim',
	'Rejected',
	'No Action Needed'
]

export const cuppingSessionSorts = {
  id: false,
  owner: false,
  time: false,
  description: false,
  location: false
}

export const sucafinaTastingTable = [
    {key: 'tastingScore', translation: 'descriptor.tasting'},
]

export const scaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragrance'},
  {abbr: 'AR', key: 'aroma', translation: 'descriptor.aroma'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'NU', key: 'nonUniformLength', translation: 'descriptor.nonUniformCups'},
  {abbr: 'DC', key: 'defectiveLength', translation: 'descriptor.defectiveCups'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const scaAffectiveScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragrance'},
  {abbr: 'AR', key: 'aroma', translation: 'descriptor.aroma'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'MO', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const scaDescriptiveScoreTable = [
  {abbr: 'FA', key: 'fragranceIntensity', translation: 'descriptor.fragrance'},
  {abbr: 'AR', key: 'aromaIntensity', translation: 'descriptor.aroma'},
  {abbr: 'FL', key: 'flavorIntensity', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertasteIntensity', translation: 'descriptor.aftertaste'},
  {abbr: 'AC', key: 'acidityIntensity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessIntensity', translation: 'descriptor.sweetness'},
  {abbr: 'MO', key: 'mouthfeelIntensity', translation: 'descriptor.mouthfeel'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const arabicaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragranceOrAroma'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'UN', key: 'totalUniformity', translation: 'descriptor.uniformity'},
  {abbr: 'CL', key: 'totalCleanCup', translation: 'descriptor.cleanCup'},
  {abbr: 'SW', key: 'totalSweetness', translation: 'descriptor.sweetness'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const robustaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragranceOrAroma'},
  {abbr: 'SA', key: 'salt', translation: 'descriptor.saltOrAcid'},
  {abbr: 'BS', key: 'bittersweet', translation: 'descriptor.bitterOrSweet'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'UN', key: 'totalUniformity', translation: 'descriptor.uniformity'},
  {abbr: 'CL', key: 'totalCleanCup', translation: 'descriptor.cleanCup'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const coeScoreTable = [
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'CL', key: 'cleanCupScore', translation: 'descriptor.cleanCup'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const commercialScoreTable = [
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const solubleScoreTable = [
  {abbr: 'AR', key: 'aroma', translation: 'descriptor.aroma'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'BI', key: 'bitterness', translation: 'descriptor.bitterness'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'CO', key: 'consistency', translation: 'descriptor.consistency'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const simplifiedCommonScoreTable = [
	{abbr: 'TS', key: 'totalScore', translation: 'descriptor.score'},
	{abbr: 'AC', key: 'acidityNote', translation: 'descriptor.acidity'},
	{abbr: 'SW', key: 'sweetnessNote', translation: 'descriptor.sweetness'},
	{abbr: 'PAD', key: 'physicalAspectDescription', translation: 'descriptor.physicalAspectDescription'},
	{abbr: 'PAR', key: 'physicalAspectRate', translation: 'descriptor.physicalAspectRate'},
	{abbr: 'OV', key: 'overallNote', translation: 'descriptor.overallExpectation'},
	{abbr: 'RS', key: 'result', translation: 'descriptor.sampleResult'},
]

export const tastingScoreTable = [
  {abbr: 'TA', key: 'tastingScore', translation: 'descriptor.tasting'},
	{abbr: 'TS', key: 'totalScore', translation: 'descriptor.score'},
]

export const sampleStatuses = [
  {key: 'approved', label: 'sample.status.approve' },
  {key: 'rejected', label: 'sample.status.reject' }
]

export const roles = [
  {key: 'quality_control', label: 'options.role.qualityControl'},
  {key: 'barista', label: 'options.role.barista'},
  {key: 'trader', label: 'options.role.trader'},
  {key: 'homebrewer', label: 'options.role.homebrewer'},
  {key: 'coffee_buyer', label: 'options.role.coffeeBuyer'},
  {key: 'owner', label: 'options.role.owner'},
  {key: 'educator', label: 'options.role.educator'},
  {key: 'judges', label: 'options.role.judges'},
  {key: 'logistic', label: 'options.role.logistic'},
  {key: 'other', label: 'options.role.other'},
]

export const companyTypes = [
  {key: 'coffee_group', label: 'options.companyType.coffeeGroup'},
  {key: 'importer', label: 'options.companyType.importer'},
  {key: 'wholesale', label: 'options.companyType.wholesale'},
  {key: 'retail', label: 'options.companyType.retail'},
  {key: 'ngo', label: 'options.companyType.ngo'},
  {key: 'education', label: 'options.companyType.education'},
]

export const sucafinaTastingForm = [
  {value: 9, emoticon: IconSmilingFace},
  {value: 8, emoticon: IconExplodingHead},
  {value: 7, emoticon: IconFaceWithTongue},
  {value: 6, emoticon: IconZanyFace},
  {value: 5, emoticon: IconGrinningFace},
  {value: 4, emoticon: IconPersonShrugging},
  {value: 3, emoticon: IconSleepingFace},
  {value: 2, emoticon: IconTiredFace},
  {value: 1, emoticon: IconPileOfPoo}
]

export const menuList = [
  {
    value: 1,
    label: 'descriptor.extremelyLow',
    color: "#B12028"
  },
  {
    value: 2,
    label: 'descriptor.veryLow',
    color: "#BF3027"
  },
  {
    value: 3,
    label: 'descriptor.moderatelyLow',
    color: "#CD3E25"
  },
  {
    value: 4,
    label: 'descriptor.slightlyLow',
    color: "#DC4F22"
  },
  {
    value: 5,
    label: 'descriptor.neitherHighNorLow',
    color: "#FC6C17"
  },
  {
    value: 6,
    label: 'descriptor.slightlyHigh',
    color: "#D98428"
  },
  {
    value: 7,
    label: 'descriptor.moderatelyHigh',
    color: "#D98428"
  },
  {
    value: 8,
    label: 'descriptor.veryHigh',
    color: "#A4B040"
  },
  {
    value: 9,
    label: 'descriptor.extremelyHigh',
    color: "#7CD55A"
  },
]

export const scoreStep = 0.25
export const scoreCommercialStep = 1
export const maximumScore = 10
export const minimumScore = 5
export const maximumCupScore = 8
export const minimumCupScore = 0
export const maximumCommercialScore = 5
export const minimumCommercialScore = 1
export const roastLevels = [0, 25, 50, 75, 100]
export const defaultScores = range(5, 10.01, 0.25)
export const defaultScoreValue = 7.5
export const defaultScoreScaValue = 5
export const defaultScoreIntensity = 0
export const defaultScoreMarketing = 1
export const coeDefaultScores = range(0, 8.01, 0.25)
export const commercialDefaultScores = range(1, 5.01, 1)
export const defaultDefects = [0, 2, 4, 6, 8, 10, 12, 16, 20]
export const coeDefaultDefects = [0, 4, 8, 12, 16, 20, 24, 32, 40, 36, 48, 60]
export const cupOptions = [0,1,2,3,4,5]
export const intensityOptions = [0, 2, 4]
export const coeIntensityOptions = [0, 1, 2, 3]
export const coeFactor = 4
export const coeAdditionalScore = 36
export const solubleRoastLevels = [25, 75]
export const defaultScoreSolubleValue = 0
export const formatDate = "MM/DD/YYYY"
export const formatDateWithTime = "MM/DD/YYYY HH:mm"
