/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types, flow, applySnapshot } from "mobx-state-tree"

import { ApiResult } from "services/api"
import { 
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { CupperModel } from 'models/cupper'
import { withDashboardActions } from "./dashboard-action"
import { filterModel } from "./filter-model"

/**
 * Model description here for TypeScript hints.
 */
export const DashboardStoreModel = types
  .model("DashboardStore")
  .props({
    latestActivities: types.optional(types.array(types.model({
      description: types.maybeNull(types.string),
      startsAt: types.maybeNull(types.string)
    })), []),
    upcomingCupping: types.optional(types.array(types.model({
      ownerName: types.string,
      startsAt: types.string,
      endsAt: types.string,
      numberForCompany: types.number,
      name: types.string,
      hasGuestInvitation: types.maybe(types.boolean),
      cuppers: types.array(CupperModel)
    })), []),
    samplesCupped: types.optional(types.number, 0),
    cuppingSessions: types.optional(types.number, 0),
    highestScoreOfThisWeek: types.optional(types.number, 0),
    lowestScoreOfThisWeek: types.optional(types.number, 0),
    weeklyGoalSamplesCupped: types.optional(types.number, 0),
    weeklyGoalSamplesCuppedMax: types.optional(types.number, 0),
    weeklyGoalCuppingSessions: types.optional(types.number, 0),
    weeklyGoalCuppingSessionsMax: types.optional(types.number, 0),
    sampleCuppedWeekly: types.optional(types.number, 0),
    sampleCuppedHeldWeekly: types.optional(types.number, 0),
    sampleCuppedMonthly: types.optional(types.number, 0),
    sampleCuppedHeldMonthly: types.optional(types.number, 0),
    sampleCuppedQuarterly: types.optional(types.number, 0),
    sampleCuppedHeldQuarterly: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withDashboardActions)
  .actions(self => ({
    setStore(data) {
      applySnapshot(self, data)
    }
  }))
  .actions(self => ({
    getDashboardData: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.dashboardApi.all()

        if (res.kind === "ok") self.setStore(res.data)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }))
  

type DashboardStoreType = Instance<typeof DashboardStoreModel>
export interface DashboardStore extends DashboardStoreType {}
type DashboardStoreSnapshotType = SnapshotOut<typeof DashboardStoreModel>
export interface DashboardStoreSnapshot extends DashboardStoreSnapshotType {}
