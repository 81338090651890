import React from "react";
import {camelCase, find, isEmpty, map, startCase, sumBy} from "lodash";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {
  Box,
  Button,
  Checkbox, Collapse,
  Container,
  FormControlLabel,
  Grid, IconButton,
  Paper, Stack,
  Table, TableBody, TableCell, TableContainer, TableFooter,
  TableHead, TableRow,
  Typography
} from "@mui/material";
import {centered, colors} from "assets";
import moment from "moment";
import clsx from "clsx";
import ReactWordcloud from "react-wordcloud";
import {Radar} from "react-chartjs-2";
import {
	countryName,
	descriptorSelection,
	descriptorTranslation,
	flavorWheel,
	minMaxGraph,
	optionsTranslation
} from "utils";
import {allDescriptor} from "constants/all-descriptors";

import {useStyles} from "./report.styles";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {
	arabicaScoreTable,
	coeScoreTable, commercialScoreTable,
	robustaScoreTable,
	scaAffectiveScoreTable,
	scaDescriptiveScoreTable,
	scaScoreTable,
	simplifiedCommonScoreTable,
	solubleScoreTable,
	sucafinaTastingForm,
  tastingScoreTable
} from "constants/form";
import {useStores} from "models";
import {Logo} from "assets/images";
import { useLocation } from "react-router-dom";

export const Report = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const locations = useLocation();
  const { state } = locations;
  const cuppingConfiguration = state ? (state as any).cuppingStore : undefined;

  const {
    id, ids, isShowCupper, groupDefects, isPrinting, renderShareButtonMobileVersion,
    title, cuppingProtocol, cuppingDate, scores, averageIntensities,
    averageScoresForGraph, cloudWords, flavorWheelOnlyFragrance, flavorWheelOnlyFlavor, flavorWheelGroups,
    allScoreDescriptors, sampleRecord, emoticons, interestStatus, preventReloadFlavorWheel = true, combinedReport = false
  } = props

  const {
    userStore: { isAuthenticated, languagePreference },
    companyStore: { base64Logo, isEnterprisePlus },
  } = useStores()

  const [expanded, setExpanded] = React.useState(false)
  const [selectedIndexExpanded, setSelectedIndexExpanded] = React.useState("")
  const [isFragranceWheel, setIsFragranceWheel] = React.useState(true)
  const [isFlavorWheel, setIsFlavorWheel] = React.useState(true)

  const descriptorLabels = allScoreDescriptors ? descriptorSelection(allDescriptor, allScoreDescriptors) : []
  const isSca = cuppingProtocol === 'sca'
  const isScaDescriptive = cuppingProtocol === 'sca_descriptive'
  const isScaAffective = cuppingProtocol === 'sca_affective'
  const isSucafinaTesting = cuppingProtocol === 'sucafina_tasting'

  const scoreTable = () => {
    switch (cuppingProtocol) {
      case 'arabica':
        return arabicaScoreTable
      case 'robusta':
        return robustaScoreTable
      case 'cup_of_excellence':
        return coeScoreTable
      case 'sca_descriptive':
        return scaDescriptiveScoreTable
      case 'sca_affective':
        return scaAffectiveScoreTable
      case 'sca':
        return scaScoreTable
      case 'commercial':
        return commercialScoreTable
      case 'soluble':
        return solubleScoreTable
	    case 'simplified_common':
		    return simplifiedCommonScoreTable
      case 'sucafina_tasting':
        return tastingScoreTable
      default:
        return []
    }
  }

  const renderInfo = (data: string) => {
    if (cuppingProtocol === "sca") {
      return (
        <Box>
          <Button
            variant="outlined"
            sx={{ mb: 1, borderRadius: 5, background: colors.text.o25, border: 0,  pointerEvents: "none" }}
          >
            {t(`descriptor.${data}`)}
          </Button>
        </Box>
      )
    }
    return null
  };

  const renderInfoAvgIntensities = (label: string, value: string | number) => {
    return (
      <Box display="flex" alignItems="end" justifyContent="space-between" mb={0.5}>
        <Typography variant="body2" style={{wordBreak: 'break-word'}}>{label.replace("Intensity", "").trim()}</Typography>
        <Typography variant="subtitle1"> {value !== null ? value : 0}</Typography>
      </Box>
    )
  }

  const renderAvgIntensities = (scores) => {

    return (
      <>  
      <Typography variant='h4' mb={1}>{t('report.avgIntensities')}</Typography>
      {renderInfo("descriptive")}
      <Box className={classes.avgIntensitiesWrapper} width="55%">
        {Object.keys(scores).map(key => { return renderInfoAvgIntensities(startCase(key), scores[key]) })}
      </Box>
      </>
    )
  }

  const renderEmoticon = (value: number) => {
    const emo = find(sucafinaTastingForm, ['value', value])
    if (!emo) return null

    return (
      <emo.emoticon width={24} height={24} />
    )
  }

  const poweredImage = (absolute = false) => {
    return (
      <Box className={clsx(classes.powered, absolute && 'absolute')}>
        <Typography variant='body3' sx={{mr: 0.5}}>{t('report.poweredBy')}</Typography>
        <Logo width={50} height={25} />
      </Box>
    )
  }

  const renderSucafinaTastingForm = () => {
    return(
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap"  sx={{p : 3, justifyContent: 'center'}}>
            {emoticons.map(emoticon => renderEmoticon(emoticon))}
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const renderDescriptionItem = (label: string, value: string) => {
    return (
      <Grid item xs={6}>
        <Typography variant='body1'>{label}</Typography>
        <Typography variant='h6' color='primary'>{value || '-'}</Typography>
      </Grid>
    )
  }

  const renderDescriptionItemMobileVersion = (label: string, value : string) => {
    return (
      <Grid container sx={{ p: 1, borderBottom: 1, borderColor: colors.primary.o25 }}>
        <Grid item xs={6}>
          <Box sx={{ width: '100%', height: '25px', mt: 1 }}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: '100%', textAlign: 'end', height: '25px', mt: 1 }}>
            <Typography sx={{ mr: 2, color: "#8D57B2" }}>{value || '-'}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const checkReportConfiguration = (att: string) => {
    if (!cuppingConfiguration) {
      return true;
    }

    return cuppingConfiguration[att] === true;
  }

  const renderDescriptions = (
    <Paper elevation={0} variant="outlined" sx={{p: isMobile ? 1 : 4, position: 'relative'}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          {isMobile ?
            <Grid item xs={12} md={8}>
              {checkReportConfiguration('varietals') && renderDescriptionItemMobileVersion(t('sample.varietals'), sampleRecord?.varietals?.join(', '))}
              {checkReportConfiguration('cuppingDate') && renderDescriptionItemMobileVersion(t('report.cuppingDate'), moment(cuppingDate).format('DD MMMM YYYY'))}
              {checkReportConfiguration('country') && renderDescriptionItemMobileVersion(t('sample.country'), sampleRecord?.countryCode)}
              {checkReportConfiguration('process') && renderDescriptionItemMobileVersion(t('sample.process'), sampleRecord?.processName)}
              {checkReportConfiguration('referenceNumber') && renderDescriptionItemMobileVersion(t('sample.referenceNumber'), sampleRecord?.sampleReference)}
              {checkReportConfiguration('producer') && renderDescriptionItemMobileVersion(t('sample.producer'), sampleRecord?.producerName)}
              {checkReportConfiguration('moisture') && renderDescriptionItemMobileVersion(t('sample.moisture'), sampleRecord?.moisture && `${sampleRecord?.moisture} %`)}
              {checkReportConfiguration('waterActivity') && renderDescriptionItemMobileVersion(t('sample.waterActivity'), sampleRecord?.waterActivity)}
            </Grid> :
            <Grid container spacing={isPrinting ? 1 : 2}>
	            {checkReportConfiguration('sampleId') && renderDescriptionItem(t('sample.sampleId'), sampleRecord?.sampleUniqueNumber)}
              {checkReportConfiguration('purchaseGrade') && renderDescriptionItem(t('sample.samples.purchaseGrade'), sampleRecord?.purchaseGrade)}
	            {checkReportConfiguration('purchaseContract') && renderDescriptionItem(t('sample.samples.purchaseContract'), sampleRecord?.purchaseContractReference)}
	            {checkReportConfiguration('salesContract') && renderDescriptionItem(t('sample.samples.salesContract'), sampleRecord?.salesContractReference)}
              {checkReportConfiguration('cropYear') && renderDescriptionItem(t('sample.cropYear'), sampleRecord?.cropYear)}
              {checkReportConfiguration('species') && renderDescriptionItem(t('sample.species'), startCase(sampleRecord?.species))}
              {checkReportConfiguration('referenceNumber') && renderDescriptionItem(t('sample.referenceNumber'), sampleRecord?.referenceNumber)}
              {checkReportConfiguration('cuppingDate') && renderDescriptionItem(t('report.cuppingDate'), moment(cuppingDate).format('DD MMMM YYYY'))}
              {checkReportConfiguration('country') && renderDescriptionItem(t('sample.country'), sampleRecord?.country || countryName(sampleRecord?.countryCode))}
              {checkReportConfiguration('producer') && renderDescriptionItem(t('sample.producer'), sampleRecord?.producerName)}
              {checkReportConfiguration('density') && !combinedReport && renderDescriptionItem(t('sample.density'), sampleRecord?.density && `${sampleRecord?.density} g/L`)}
              {checkReportConfiguration('trackingNumber') && renderDescriptionItem(t('sample.trackingNumber'), sampleRecord?.trackingNumber)}
              {checkReportConfiguration('moisture') && !combinedReport && renderDescriptionItem(t('sample.moisture'), sampleRecord?.moisture && `${sampleRecord?.moisture} %`)}
              {checkReportConfiguration('process') && renderDescriptionItem(t('sample.process'), sampleRecord?.processName)}
              {checkReportConfiguration('waterActivity') && !combinedReport && renderDescriptionItem(t('sample.waterActivity'), sampleRecord?.waterActivity)}
              {checkReportConfiguration('varietals') && renderDescriptionItem(t('sample.varietals'), sampleRecord?.varietals?.join(', '))}
            </Grid>
          }
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Box className={classes.totalScoreWrapper}>
          {(isScaDescriptive || isSca) && renderAvgIntensities(averageIntensities)}
          {!isScaDescriptive &&
            <>
              <Typography variant='h4'>{t('sampleScore.totalScore')}</Typography>
              {renderInfo("affective")}
              <Typography variant='h1' color='primary'>{sampleRecord?.cachedAverageScore}</Typography>
            </>
          }
            {sampleRecord?.status &&
              <Typography
                variant='h4'
                className={clsx(sampleRecord.status && classes[sampleRecord.status])}
              >
                {t(`sample.status.${sampleRecord.status}`)}
              </Typography>
            }
          </Box>
        </Grid>
        {poweredImage(true)}
      </Grid>
    </Paper>
  )

  const showCloudWords = (
    <Paper elevation={0} variant="outlined" sx={{p: 4}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{maxHeight: {md: isPrinting ? 400 : 600}}}>
          <ReactWordcloud
            words={cloudWords}
            options={{
              rotations: 1,
              rotationAngles: [0, 0],
              fontSizes: [20, 50],
              enableTooltip: false
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )

  const showRadar = (
    <Paper elevation={0} variant="outlined" sx={{p: 4}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{
             maxHeight: { md: isPrinting ? 400 : 600 },
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
             textAlign: 'center',
          }}>
        <Radar
            data={averageScoresForGraph}
            options={{
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                r: {
                  ...minMaxGraph(averageScoresForGraph.datasets[0].data),
                  pointLabels: {
                    font: {
                      size: 16,
                    }
                  }
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )

  const renderSpiderWord = (
    <Paper elevation={0} variant="outlined" sx={{p: 4}}>
      <Grid container spacing={2}>
        <Grid item xs={isPrinting ? 6 : 12} md={6} sx={{maxHeight: {md: isPrinting ? 400 : 600}}}>
          <ReactWordcloud
            words={cloudWords}
            options={{
              rotations: 1,
              rotationAngles: [0, 0],
              fontSizes: [20, 50],
              enableTooltip: false
            }}
          />
        </Grid>
        <Grid item xs={isPrinting ? 6 : 12} md={6} sx={{...centered}}>
          <Radar
            data={averageScoresForGraph}
            options={{
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                r: {
                  ...minMaxGraph(averageScoresForGraph.datasets[0].data),
                  pointLabels: {
                    font: {
                      size: 16,
                    }
                  }
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  )

  const renderDefects = (type, defects) => {
    if (!defects) return null

    const total = sumBy(defects, defect => (defect as any).total)
    if (total === 0) return null

    return (
      <Grid item xs={12} md={6}>
        <Table size='small' className={classes.tableDefect}>
          <TableHead>
            <TableRow>
              <TableCell>{startCase(type)}</TableCell>
              <TableCell align='center'>{t('report.count')}</TableCell>
              <TableCell align='center'>{t('report.total')}</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defects.map((defect, index) => {
              if (defect.count === 0) return null

              return (
                <TableRow key={index}>
                  <TableCell>{defect.defectType}</TableCell>
                  <TableCell className={classes.primary} align='center'>{defect.count}</TableCell>
                  <TableCell className={classes.primary} align='center'>{defect.total}</TableCell>
                  <TableCell className={classes.primary} align='center'>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align='center'>
                <Typography variant='subtitle1'>{total}</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    )
  }

  const renderSessionDetails = (scoreTable) => (
    <Paper elevation={0} variant="outlined" sx={{p: isMobile ? 2 : 4}}>
      <Typography variant='subtitle1'>{t('report.cuppingScores')}:</Typography>
      <TableContainer sx={{ maxWidth: '100%', overflowX: isPrinting ? 'unset' : 'auto' }}>
        <Table stickyHeader size='small' sx={{my: 2, tableLayout: isPrinting ? 'fixed' : 'unset'}}>
          <TableHead>
            <TableRow>
              <TableCell className={clsx('sticky-cell', isMobile && classes.cellMobile)}>
                <Typography variant='subtitle1'>{t('report.cupper')}</Typography>
              </TableCell>
              {scoreTable.map(tc =>
                <TableCell key={tc.abbr} align="center" className={clsx(isMobile && classes.cellMobile)}>
                  <Typography variant='subtitle1'>{tc.abbr}</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {scores.map((sc, index) =>
              <TableRow key={`${cuppingProtocol}${index}`}>
                <TableCell className={clsx('sticky-cell', isMobile && classes.cellMobile)}>
                  <Typography variant='body3'>
                    {ids[ids.findIndex( x => x === sc.id)] ? sc.cupperName : `${t('report.cupper')} ${index + 1}`}
                  </Typography>
                </TableCell>
                {scoreTable.map(tc =>
                  <TableCell key={tc.abbr} align="center" className={clsx(isMobile && classes.cellMobile)}>
                    <Typography variant='body3' color='primary'>
                      {sc[tc.key]}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{mb: 2}}>
        {scoreTable.map((tc, index) =>
          <React.Fragment key={index}>
            <Typography variant='body3' className={clsx(classes.legend, 'bold')}>{tc.abbr}: </Typography>
            <Typography variant='body3' className={classes.legend}>
              {t(tc.translation)} {scoreTable.length - 1 !== index && ' - '}
            </Typography>
          </React.Fragment>
        )}
      </Box>
      {!isEmpty(descriptorLabels) &&
        <>
          <Typography variant='subtitle1'>{t('report.descriptors')}:</Typography>
          {descriptorLabels.map((descriptor, index) =>
            <React.Fragment key={index}>
              <Typography variant='body3' color='primary' fontWeight={500} component='span'>{descriptorTranslation(languagePreference, descriptor)}</Typography>
              {descriptorLabels.length - 1 !== index && <Typography variant='body3' color='text.disabled'> | </Typography>}
            </React.Fragment>
          )}
        </>
      }

      {map(scores, 'notes').some(Boolean) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 2}}>{t('descriptor.notes')}:</Typography>
          {scores.map((sc, index) => {
            if (!sc.notes) return null

            return (
              <Grid key={index} container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='body3' fontWeight={500}>
                    {isShowCupper ? sc.cupperName : `${t('report.cupper')} ${index + 1}`}
                  </Typography>
                </Grid>
                <Grid item xs><Typography variant='body3' sx={{wordBreak: 'break-word'}}>{sc.notes}</Typography></Grid>
              </Grid>
            )
          })}
        </>
      }

      {!isEmpty(groupDefects) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 3}}>{t('descriptor.defects')}:</Typography>
          <Grid container spacing={2}>
            {Object.keys(groupDefects).map(key => renderDefects(key, groupDefects[key]))}
          </Grid>
        </>
      }
    </Paper>
  )

  const renderResultDetailMobileVersion = (
    <Box>
      <Typography sx={{p: 1, mt: 1}}>{t("report.cuppingScores")}</Typography>
      {scores.map((sc, i) => {

        return (
          <>
            <Grid container sx={{p:1, borderBottom: 1, borderColor: colors.primary.o25}} key={i}>
              <Grid item xs={10} sx={{pt: 1.4}}>
                <Typography color="#8D57B2">{`Cuppers ${i + 1}`}</Typography>
              </Grid>
              <Grid item xs={2} >
                <IconButton
                  size="medium"
                  onClick={() => {
                    setExpanded(!expanded)
                    if (selectedIndexExpanded === i) {
                      setSelectedIndexExpanded("")
                    } else {
                      setSelectedIndexExpanded(i)
                    }
                  }}
                  sx={{ pl: 3 }}
                >
                  {i === selectedIndexExpanded  ? !isPrinting && <ArrowDropUp sx={{ fontSize: "30px" }} /> : !isPrinting && <ArrowDropDown sx={{ fontSize: "30px" }} />}
                </IconButton>
              </Grid>
              <Grid item xs={12} >
                <Collapse in={i === (isPrinting ? i: selectedIndexExpanded)} timeout="auto" unmountOnExit sx={{ ml: 1, p: isPrinting ? 2:0 }}>
                  {(isShowCupper || isPrinting) &&
                    <>
                      <Grid container justifyContent="space-between" sx={{ ml: -1, borderBottom: 1, borderColor: colors.primary.o25 }}>
                        <Grid item xs={6} >
                          <Box sx={{ width: '100%', height: '30px', mt: 2 }}>
                            <Typography>{t('sample.cuppers')}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ width: '100%', textAlign: 'end', height: '30px', mt: 2 }}>
                            <Typography sx={{ mr: 2, color: "#8D57B2" }}>{sc.cupperName}</Typography>
                          </Box>

                        </Grid>

                      </Grid>
                    </>
                  }

                  {scoreTable().map(tc => {
                    return (
                      <Grid container justifyContent="space-between" sx={{ ml: -1, borderBottom: 1, borderColor: colors.primary.o25 }}>
                        <Grid item xs={6} >
                          <Box sx={{ width: '100%', height: '30px', mt: 2 }}>
                            <Typography>{t(tc.translation)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box sx={{ width: '100%', textAlign: 'end', height: '30px', mt: 2 }}>
                            <Typography sx={{ mr: 2, color: "#8D57B2" }}>  {sc[tc.key]}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  })}
                  <Grid container justifyContent="space-between" sx={{ml: -1}}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' sx={{ mb: 1, mt: 2 }}>{t('report.descriptors')}:</Typography>
                      {descriptorLabels.map((descriptor, index) =>
                        <React.Fragment key={index}>
                          <Typography variant='body3' color='primary' fontWeight={500} component='span'>{descriptorTranslation(languagePreference, descriptor)}</Typography>
                          {descriptorLabels.length - 1 !== index && <Typography variant='body3' color='text.disabled'> | </Typography>}
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" sx={{ml: -1, mb: isPrinting ? 5:0}}>
                    <Grid item xs={2} >
                      <Box sx={{width: '100%', height: '30px', mt: 2}}>
                        <Typography>{t('descriptor.notes')}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box sx={{width: '100%', textAlign: 'end',height: '30px',mt: 2}}>
                        <Typography sx={{ color: "#8D57B2"}}>{sc.notes}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {(isPrinting && (i + 1) % 2 === 0) &&  <div className="html2pdf__page-break" style={{breakAfter:"page"}}/>}
          </>
        )

      })}
      {!isEmpty(groupDefects) &&
        <>
          <Typography variant='subtitle1' sx={{mt: 2, ml: 1}}>{t('descriptor.defects')}:</Typography>
          <Grid container spacing={2}>
            {Object.keys(groupDefects).map(key => renderDefects(key, groupDefects[key]))}
          </Grid>
        </>
      }
    </Box>
  )

  const wheelId = id ? 'tastify-wheel' : `tastify-wheel-${id}`;

  React.useEffect(() => {
    if (isPrinting) return

    flavorWheel(flavorWheelGroups, isEnterprisePlus && base64Logo, wheelId)
  }, [flavorWheelGroups])

  React.useEffect(() => {
    if (isPrinting && preventReloadFlavorWheel) return

    if (isFragranceWheel && isFlavorWheel) {
      flavorWheel(flavorWheelGroups, isEnterprisePlus && base64Logo, wheelId)
      return
    }

    if (isFragranceWheel) {
      flavorWheel(flavorWheelOnlyFragrance, isEnterprisePlus && base64Logo, wheelId)
      return
    }

    flavorWheel(flavorWheelOnlyFlavor, isEnterprisePlus && base64Logo, wheelId)
  }, [isFragranceWheel, isFlavorWheel])

  return (
    <Box>
      {isPrinting &&
        <Box>
          <Typography variant='h4'>{title}</Typography>
        </Box>
      }

      <Box sx={{mb: 2, display: 'flex'}}>
        <Typography variant='body2'>{t('report.cuppedUsing')}</Typography>
        &nbsp;
        <Typography variant='h6'>
          {optionsTranslation('cuppingProtocol', cuppingProtocol)}
          &nbsp;
          {t(`cuppingSession.cuppingForm`)}
        </Typography>
      </Box>

      <Box sx={{mb: 2}}>
        <Grid container spacing={2}>
          <Grid item xs={isPrinting ? 8 : 12} md={isScaAffective ? 12 : 8}>
            {(isMobile && isAuthenticated) && !isPrinting && renderShareButtonMobileVersion}
            {renderDescriptions}
          </Grid>
          {!isScaAffective &&
            <Grid item xs={isPrinting ? 4 : 12} md={4}>

           
              <Box className={classes.wheelWrapper} >
                {renderInfo("descriptive")}
                <div id={wheelId} className={classes.wheel}></div>
                <Box sx={{textAlign: 'center'}}>
                {poweredImage()}
                {!isSucafinaTesting &&
                  <>
                    <FormControlLabel
                      label={`${t('descriptor.fragranceOrAroma')}`}
                      control={
                        <Checkbox
                          size="small"
                          disabled={!isFlavorWheel}
                          checked={isFragranceWheel}
                          onChange={() => setIsFragranceWheel(!isFragranceWheel)}
                        />
                      }
                    />
                    <FormControlLabel
                      label={`${t('descriptor.flavor')}`}
                      control={
                        <Checkbox
                          size="small"
                          disabled={!isFragranceWheel}
                          checked={isFlavorWheel}
                          onChange={() => setIsFlavorWheel(!isFlavorWheel)}
                        />
                      }
                    />
                  </>
                }
              </Box>
              </Box>
            </Grid>
          }
        </Grid>
      </Box>

      {(cuppingProtocol !== 'sucafina_tasting' && cuppingProtocol !== 'simplified_common' && cuppingProtocol !== 'sca_affective' && cuppingProtocol !== 'sca_descriptive' && !isSca) &&
        <Box sx={{mb: 2}}>
          <Typography variant='h6' sx={{mb: 1}}>{t('report.theCup')}</Typography>
          {renderSpiderWord}
        </Box>
      }

      {(cuppingProtocol === 'simplified_common' && !isEmpty(cloudWords)) &&
          <Box sx={{mb: 2}}>
            <Typography variant='h6' sx={{mb: 1}}>{t('report.theCup')}</Typography>
            {showCloudWords}
          </Box>
      }

      {(cuppingProtocol === 'sucafina_tasting' && !isEmpty(cloudWords)) &&
          <Box sx={{mb: 2}}>
            <Typography variant='h6' sx={{mb: 1}}>{t('report.theCup')}</Typography>
            {showCloudWords}
          </Box>
      }

      <div className="html2pdf__page-break" style={{breakAfter:"page"}}/>

      {(isSca || cuppingProtocol === 'sca_affective') &&
        <Box sx={{mb: 2}}>
          <Typography variant='h6' sx={{mb: 1}}>{t('report.theCup')}</Typography>
          {isSca &&<Typography variant='body1' sx={{mb: 1, color: colors.primary.main}}>{!isSucafinaTesting && t('descriptor.affective')}</Typography>}
          {showRadar}
        </Box>
      }
      
      {isSca && 
         <Box sx={{mb: 6}}>
         <Typography variant='h6' sx={{mb: 1}}>{!isSucafinaTesting && t('report.sessionDetail')}</Typography>
         <Typography variant='body1' sx={{mb: 1, color: colors.primary.main}}>{!isSucafinaTesting && t('descriptor.descriptive')}</Typography>
         {isMobile ? renderResultDetailMobileVersion  : renderSessionDetails(scaDescriptiveScoreTable)}
       </Box>
      }
      
      <Box sx={{mb: 6, mt: isPrinting? 2 : 0}}>
        <Typography variant='h6' sx={{mb: 1}}>{!isSucafinaTesting && t('report.sessionDetail')}</Typography>
        {isSca && <Typography variant='body1' sx={{mb: 1, color: colors.primary.main}}>{!isSucafinaTesting && t('descriptor.affective')}</Typography>}
        {isMobile ? renderResultDetailMobileVersion  : renderSessionDetails(scoreTable())}
      </Box>

    </Box>
  )
}