/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-01 21:15:06
 * @modify date 2022-03-01 21:15:06
 */

import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withReset,
  withSetValue,
  withRootStore
} from "models/extensions"

/**
 * Model description here for TypeScript hints.
 */
export const CuppingSessionModel = types
  .model("CuppingSession")
  .props({
    id: types.identifier,
    numberForCompany: types.optional(types.number, 0),
    ownerName: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    startsAt: types.maybeNull(types.string),
    endsAt: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    samplesWithInfoCount: types.optional(types.number, 0),
    samplesCount: types.optional(types.number, 0),
    addSampleInfoTooltipMessage: types.maybeNull(types.string),
    reasonCanNotBeEdited: types.maybeNull(types.string),
    reasonCanNotCupNow: types.maybeNull(types.string),
    reasonCanNotBeReviewed: types.maybeNull(types.string),
    reasonCanNotBeDeleted: types.maybeNull(types.string),
    canNotAddSampleInfo: types.optional(types.boolean, true),
    canNotBeEdited: types.optional(types.boolean, true),
    canBeStarted: types.optional(types.boolean, true),
    canNotCupNow: types.optional(types.boolean, true),
    canNotBeReviewed: types.optional(types.boolean, true),
    canNotBeDeleted: types.optional(types.boolean, true),
    canNotResendToWebhook: types.optional(types.boolean, true),
    uniqueToken: types.maybeNull(types.string),
    cuppingProtocol: types.optional(types.string, ''),
    hasSubmittedScores: types.optional(types.boolean, false),
    isOwner: types.optional(types.boolean, false),
    isPublic: types.maybeNull(types.optional(types.boolean, false)),
    isPaused: types.maybeNull(types.optional(types.boolean, false)),
    blind: types.optional(types.boolean, false),
    publicCuppingRequestInformation: types.maybeNull(types.model({
      id: types.maybeNull(types.number),
      cuppingSessionId: types.maybeNull(types.number),
      nameStatus: types.maybeNull(types.number),
      emailStatus: types.maybeNull(types.number),
      companyNameStatus: types.maybeNull(types.number),
      roleStatus: types.maybeNull(types.number),
    })),
    comboCuppingFlag: types.maybeNull(types.optional(types.boolean, false)),
    samples: types.frozen()
  })
  .extend(withRootStore)
  .extend(withSetValue)
  .extend(withReset)

type CuppingSessionType = Instance<typeof CuppingSessionModel>
export interface CuppingSession extends CuppingSessionType {}
type CuppingSessionSnapshotType = SnapshotOut<typeof CuppingSessionModel>
export interface CuppingSessionSnapshot extends CuppingSessionSnapshotType {}
