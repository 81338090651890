import React, { useState } from "react";
import moment from "moment";
import {ceil, chunk, groupBy, sumBy} from "lodash";
import {useTranslation} from "react-i18next";
import {Box, Card, Grid, Stack, Table, TableBody, TableCell, TableRow, Typography, CircularProgress} from "@mui/material";

import {colors} from "assets";
import {isValidJSON, optionsTranslation} from "utils";
import { useLocation } from "react-router-dom";

function ImageWithLoader({ src, alt, ...props }) {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {src && loading && (
        <div
          style={{
            marginTop:'30%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </div>
      )}
      {src && (
        <img
          src={src}
          alt={alt}
          onLoad={() => setLoading(false)}
          onError={() => setLoading(false)}
          style={{ display: loading ? 'none' : 'block', width: '100%' }}
          {...props}
        />
      )}
    </div>
  );
}

export const GreenGradingReport = (props) => {
  const { t } = useTranslation()
  const {selectedGreenGrading, total, isPrinting} = props

  const groupDefects = groupBy(selectedGreenGrading.defects, 'category')
  const totalDefects = isValidJSON(total) ? JSON.parse(total) : total

  const locations = useLocation();
  const { state } = locations;
  const greenGradingConfiguration = state ? (state as any).greenGradingStore : undefined;

  const checkReportConfiguration = (att: string) => {
    if (!greenGradingConfiguration) {
      return true;
    }

    return greenGradingConfiguration[att] === true;
  }

  const greenGradingInformation: { title: string; value: string }[] = [];

  if (checkReportConfiguration('author')) {
    greenGradingInformation.push({ title: t('greenGrading.author'), value: selectedGreenGrading?.author ?? '-' });
  }
  if (checkReportConfiguration('purchaseGrade')) {
    greenGradingInformation.push({ title: t('greenGrading.purchaseGrade'), value: selectedGreenGrading?.purchaseGrade ?? '-' });
  }
  if (checkReportConfiguration('purchaseContract')) {
    greenGradingInformation.push({ title: t('greenGrading.purchaseContract'), value: selectedGreenGrading?.purchaseContractReference ?? '-' });
  }
  if (checkReportConfiguration('salesContract')) {
    greenGradingInformation.push({ title: t('greenGrading.salesContract'), value: selectedGreenGrading?.salesContractReference ?? '-' });
  }
  if (checkReportConfiguration('color')) {
    greenGradingInformation.push({ title: t('greenGrading.color'), value: optionsTranslation('color', selectedGreenGrading?.color, true) })
  }
  if (checkReportConfiguration('weight')) {
    greenGradingInformation.push({ title: t('greenGrading.weight'), value: `${selectedGreenGrading?.weight ?? '-'} gr` })
  }
  if (checkReportConfiguration('smell')) {
    greenGradingInformation.push({ title: t('greenGrading.smell'), value: selectedGreenGrading?.smell ?? '-' })
  }
  if (checkReportConfiguration('moisture')) {
    greenGradingInformation.push({ title: t('greenGrading.moisture'), value: `${selectedGreenGrading?.moisture ?? '-'} %` })
  }
  if (checkReportConfiguration('waterActivity')) {
    greenGradingInformation.push({ title: t('greenGrading.waterActivity'), value: selectedGreenGrading?.waterActivity ?? '-' })
  }
  if (checkReportConfiguration('roastColor')) {
    greenGradingInformation.push({ title: t('greenGrading.roastColor'), value: selectedGreenGrading?.roastColor ?? '-' })
  }
  if (checkReportConfiguration('ovenTest')) {
    greenGradingInformation.push({ title: t('greenGrading.ovenTest'), value: selectedGreenGrading?.ovenTest || '-' })
  }
  if (checkReportConfiguration('otaTest')) {
    greenGradingInformation.push({ title: t('greenGrading.otaTest'), value: selectedGreenGrading?.otaTest || '-' })
  }
  if (checkReportConfiguration('glyphosateTest')) {
    greenGradingInformation.push({ title: t('greenGrading.glyphosateTest'), value: selectedGreenGrading?.glyphosateTest || '-' })
  }
  if (checkReportConfiguration('density')) {
    greenGradingInformation.push({ title: t('greenGrading.density'), value: `${selectedGreenGrading?.density ?? '-'} g/ml` })
  }
  if (checkReportConfiguration('yieldFactor')) {
    greenGradingInformation.push({ title: t('greenGrading.yieldFactor'), value: `${selectedGreenGrading?.yieldFactor ?? '-'}  %` })
  }

  const screens = [
    ...(selectedGreenGrading?.size10 ? [{title: t('greenGrading.screenSize.size10'), value: selectedGreenGrading?.size10}] : []),
    ...(selectedGreenGrading?.size11 ? [{title: t('greenGrading.screenSize.size11'), value: selectedGreenGrading?.size11}] : []),
    ...(selectedGreenGrading?.size12 ? [{title: t('greenGrading.screenSize.size12'), value: selectedGreenGrading?.size12}] : []),
    ...(selectedGreenGrading?.size13 ? [{title: t('greenGrading.screenSize.size13'), value: selectedGreenGrading?.size13}] : []),
    ...(selectedGreenGrading?.size14 ? [{title: t('greenGrading.screenSize.size14'), value: selectedGreenGrading?.size14}] : []),
    ...(selectedGreenGrading?.size15 ? [{title: t('greenGrading.screenSize.size15'), value: selectedGreenGrading?.size15}] : []),
    ...(selectedGreenGrading?.size16 ? [{title: t('greenGrading.screenSize.size16'), value: selectedGreenGrading?.size16}] : []),
    ...(selectedGreenGrading?.size17 ? [{title: t('greenGrading.screenSize.size17'), value: selectedGreenGrading?.size17}] : []),
    ...(selectedGreenGrading?.size18 ? [{title: t('greenGrading.screenSize.size18'), value: selectedGreenGrading?.size18}] : []),
    ...(selectedGreenGrading?.size19 ? [{title: t('greenGrading.screenSize.size19'), value: selectedGreenGrading?.size19}] : []),
    ...(selectedGreenGrading?.size20 ? [{title: t('greenGrading.screenSize.size20'), value: selectedGreenGrading?.size20}] : []),
  ]

  const splitScreens = ceil(screens.length/6)

  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={12} md={isPrinting ? 12 : 6}>
        <Card sx={{p: 3, height: '100%'}}>
          <Box>
            <Typography display="inline" variant="h6">
              {selectedGreenGrading.sampleName}
            </Typography>
            &nbsp;
            <Typography display="inline" variant="body1">
              ({selectedGreenGrading.sampleUniqueNumber})
            </Typography>
          </Box>
          <Box>
            <Typography display="inline" variant="body2">
              {t('greenGrading.gradingProtocol')}
            </Typography>
            &nbsp;
            <Typography display="inline" variant="body2" color="primary" fontWeight={600}>
              {selectedGreenGrading.greenGradingTypeName}
            </Typography>
          </Box>
          <Typography display="inline" variant="body2">
            {t('greenGrading.createdAt')}: {moment(selectedGreenGrading.createdAt).format('DD MMMM YYYY - HH:mm')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <Box>
              <Grid container>
                <Grid item xs={isPrinting ? 6 : 12} md={6}>
                  <Grid container spacing={1} mt={2}>
                    {greenGradingInformation.map((info, index) => (
                      <Grid item xs={6} key={index}>
                        <Typography fontSize={12}>
                          {info.title}
                        </Typography>
                        <Typography fontSize={12} fontWeight={600} color="primary">
                          {info.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={isPrinting ? 6 : 12} md={6}>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography fontSize={12} color="primary">
                      {t('greenGrading.roastedBeanPhoto')}
                    </Typography>
                    <ImageWithLoader
                      src={selectedGreenGrading?.picture}
                      alt="Roasted Bean Photo"
                    />
                    <Typography fontSize={12} color="primary">
                      {t('greenGrading.greenBeanPhoto')}
                    </Typography>
                    <ImageWithLoader
                      src={selectedGreenGrading?.picture2}
                      alt="Green Bean Photo"
                    />
                  </Stack>
                </Grid>

              </Grid>
            </Box>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={isPrinting ? 6 : 12} md={6}>
        <Card sx={{p: 3, height: '100%'}}>
          <Typography variant="h6">
            {t('greenGrading.defects')}
          </Typography>
          <Table size="small" >
            <TableBody>
              <TableRow>
                <TableCell>
                  {t('greenGrading.categories')}
                </TableCell>
                <TableCell align="center">
                  {t('greenGrading.beans')}
                </TableCell>
                <TableCell align="center">
                  {t('greenGrading.defects')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{fontWeight: 600}}>
                  {t('greenGrading.primaryDefects')} (<span style={{color: colors.primary.main}}>{totalDefects?.primary}</span>)
                </TableCell>
              </TableRow>
              {groupDefects?.primary?.map((defect, index) => {
                if (defect.count <= 0 || defect.total <= 0) return null

                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defect.defectType}
                    </TableCell>
                    <TableCell align="center" sx={{color: colors.primary.main}}>
                      {defect.count}
                    </TableCell>
                    <TableCell align="center" sx={{color: colors.primary.main}}>
                      {defect.total}
                    </TableCell>
                  </TableRow>
                )
              })}

              <TableRow>
                <TableCell colSpan={3} sx={{fontWeight: 600}}>
                  {t('greenGrading.secondaryDefects')} (<span style={{color: colors.primary.main}}>{totalDefects?.secondary}</span>)
                </TableCell>
              </TableRow>
              {groupDefects?.secondary?.map((defect, index) => {
                if (defect.count <= 0) return null

                return (
                  <TableRow key={index}>
                    <TableCell>
                      {defect.defectType}
                    </TableCell>
                    <TableCell align="center">
                      {defect.count}
                    </TableCell>
                    <TableCell align="center">
                      {defect.total}
                    </TableCell>
                  </TableRow>
                )
              })}

              <TableRow>
                <TableCell colSpan={3} sx={{fontWeight: 600}}>
                  {t('greenGrading.total')} (<span style={{color: colors.primary.main}}>{totalDefects?.all}</span>)
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>

      {isPrinting ?
        <Grid item xs={6} md={6}>
          <Card sx={{p: 3, height: '100%'}}>
            <Typography variant="h6">
              {t('greenGrading.screenEvaluation')}
            </Typography>
            <Typography variant="h6">
              (1/64 inch)
            </Typography>
            <Table>
              <TableBody>
                {screens.map((screen, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {screen.title}
                    </TableCell>
                    <TableCell align="center" sx={{color: colors.primary.main}}>
                      {screen.value} gr ({((screen.value / sumBy(screens, "value")) * 100).toFixed(2)} %)
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Grid>:
        <Grid item xs={12} md={12}>
          <Card sx={{p: 3, height: '100%'}}>
            <Typography variant="h6">
              {t('greenGrading.screenEvaluation')}
            </Typography>
            <Typography variant="h6">
              (1/64 inch)
            </Typography>
            <Grid container spacing={2} justifyContent="space-around"  alignItems="center">
              <Grid item xs={12} md={12}>
                <Grid container mt={2} >
                  {chunk(screens, splitScreens).map((screen) => {
                    return(
                      <Grid container spacing={1}  sx={{ borderBottom: 1, borderColor: colors.text.o25, mb: 1 }}>
                        {screen.map((d: any) => {
                          return(
                          <>
                            <Grid item xs={2} height={40}>
                              <Typography fontSize={12}>
                                {d.title}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} height={40}>
                              <Typography fontSize={12} fontWeight={600} color="primary">
                                {d.value} gr ({((d.value / sumBy(screens, "value")) * 100).toFixed(2)} %)
                              </Typography>
                            </Grid>
                          </>
                          )
                        })}
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      }
    </Grid>
  )
}
