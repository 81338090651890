import React, {useState} from "react";
import {cloneDeep} from "lodash";
import {useTranslation} from "react-i18next";
import {createColumnHelper, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {Box, Button, Chip, Stack, Typography, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import {isMobile} from "react-device-detect";

import {fulfillmentTypes} from "constants/form";
import {BasicTable} from "components";
import {optionsTranslation} from "utils";

import {useStyles} from "./shipment-table.styles";
import {Add, ExpandMore} from "@mui/icons-material";
import { ThirdPartyEmailModal } from "../../../../components/third-party-email-modal/third-party-email-modal";

const columnHelper = createColumnHelper<any>()

export const ShipmentTable = (props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { groupSamplesToBeShipment, group, onSubmitEmail } = props

  const [openEmailModal, setOpenEmailModal] = useState(false)
  const data = cloneDeep(groupSamplesToBeShipment[group])
  const fulfilmentType = data[0].sampleFulfillmentType
  const sampleLocation = data[0].sampleLocation
  const thirdPartyEmail = data[0].thirdPartyEmail

  const renderValue = (info) => {
    if (info.renderValue()) return info.renderValue()

    return <Box textAlign="center">-</Box>
  }

  const handleSubmitEmail = (emails: string) => {
    onSubmitEmail && onSubmitEmail(emails, fulfilmentType, sampleLocation);
  }

  const renderMobile = (
    <Box>
      {data.map((row) => (
        <Accordion key={row.uniqueToken} className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box>
              <Typography variant='h6'>{row.sampleName}</Typography>
              <Typography variant="body2" color="#999FA4">
                {optionsTranslation('sampleType', row.sampleType, true)}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className="content">
            <table cellPadding={2} className={classes.table}>
              <tbody>
                <tr>
                <td>{t('shipment.table.warehouse')}</td>
                <td>{row.sample.warehouseReference}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.sampleLocation')}</td>
                  <td>{row.sampleLocation}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.cargo')}</td>
                  <td>{row.sample.cargoNumber}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.customer')}</td>
                  <td>{row.sample.customer}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.purchaseContract')}</td>
                  <td>{row.sample.purchaseContractReference}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.salesContract')}</td>
                  <td>{row.sample.salesContractReference}</td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: () => <Box textAlign="left">{t('shipment.table.type')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue(), true),
    }),
    columnHelper.accessor('sampleName', {
      id: 'sampleName',
      header: () => <Box textAlign="left">{t('shipment.table.sampleName')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.warehouseReference', {
      id: 'sample.warehouseReference',
      header: () => <Box textAlign="left">{t('shipment.table.warehouse')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.cargoNumber', {
      id: 'sample.cargoNumber',
      header: () => <Box textAlign="left">{t('shipment.table.cargo')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.customer', {
      id: 'sample.customer',
      header: () => <Box textAlign="left">{t('shipment.table.customer')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.purchaseContractReference', {
      id: 'sample.purchaseContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
      cell: renderValue,
    }),
    columnHelper.accessor('sample.salesContractReference', {
      id: 'sample.salesContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.salesContract')}</Box>,
      cell: renderValue,
    }),
  ]

  const shipmentTable = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Box className={classes.wrapper}>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "start" : "center"}
        spacing={1}
        justifyContent="space-between"
        mb={thirdPartyEmail ? 2 : 1}
      >
        <Typography variant='h6'>
          {group === fulfillmentTypes[0] ?
            t('shipment.localShipment') :
            `${t('shipment.thirdPartyShipment')} (${group})`
          }
        </Typography>
        {group !== fulfillmentTypes[0] &&
          <Button
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenEmailModal(true)}
          >
            {t("shipment.button.addEmail")}
          </Button>
        }
      </Stack>

      {thirdPartyEmail && (
        <Stack direction="row" spacing={1} alignItems="center" mb={1} useFlexGap flexWrap="wrap">
          <Typography>{t('shipment.thirdPartyEmail')}:</Typography>
          {thirdPartyEmail.split(',').map(email => (
            <Chip key={email} label={email.trim()} color="primary" />
          ))}
        </Stack>
      )}

      {isMobile ? renderMobile : <BasicTable table={shipmentTable} />}

      <ThirdPartyEmailModal isOpen={openEmailModal} onClose={() => setOpenEmailModal(false)} onSubmit={handleSubmitEmail} thirdPartyEmail={thirdPartyEmail} />
    </Box>
  )
}
