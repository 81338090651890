import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";

import { ModalWrapper } from "components";
import { colors } from "assets";
import { IconArrow, IconDelete } from "assets/images";
import { CombinedCuppingResults } from "../combined-cupping-results/combined-cupping-results";
import { CombinedGreenGradingResults } from "../combined-green-grading-results/combined-green-grading-results";
import { useStores } from "models";
import { ReportConfigurationModal } from "components/report-configuration-modal/report-configuration-modal";

interface CombinedReportModalProps {
    isOpen: boolean,
    onClose: () => void,
    selectedSamples: any,
}

export const CombinedReportModal: React.FC<CombinedReportModalProps> = (props: CombinedReportModalProps) => {
    const { isOpen, selectedSamples, onClose } = props;
    const { t } = useTranslation();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [selectedCuppingResult, setSelectedCuppingResult] = useState<{ [key: string]: Set<string> }>({});
    const [selectedGreenGradingResult, setSelectedGreenGradingResult] = useState<{ [key: string]: Set<string> }>({});
    const [sampleData, setSampleData] = useState<any[]>([]);
    const [isOpenReportConfiguration, setOpenReportConfiguration] = useState<boolean>(false);
    const [navigateUrl, setNavigateUrl] = useState<string>('');

    const {
        masterSampleStore: { getSamepleOverview },
    } = useStores()

    const onGenerateCombinedReport = () => {
        const sampleScoreIds = getSelectedCuppingResultIds().join(',');
        const greenGradingIds = getSelectedGreenGradingResultIds().join(',');
        setNavigateUrl(`/combined-report?sample_score_id=${sampleScoreIds}&green_grading_id=${greenGradingIds}`);
        handleOpenReportConfigurationModal();
    };

    const handleOpenReportConfigurationModal = () => {
        setOpenReportConfiguration(true);
    }

    const handleCloseReportConfigurationModal = () => {
        setOpenReportConfiguration(false);
    }

    const getData = async () => {
        setIsFetching(true);
        try {
            const requestTokens = selectedSamples.map(sample => sample.uniqueToken).join(',');
            const data = await getSamepleOverview(requestTokens);
            setSampleData(data);
        } finally {
            setIsFetching(false);
        }
    }

    const clearData = () => {
        setSampleData([]);
    }

    const onDeleteSample = (id: string) => {
        const newData = sampleData.filter(data => data.uniqueToken !== id);
        setSampleData(newData);

        const cuppingResult = { ...selectedCuppingResult };
        cuppingResult[id] = new Set();
        setSelectedCuppingResult(cuppingResult);

        const greenGradingResult = { ...selectedGreenGradingResult };
        greenGradingResult[id] = new Set();
        setSelectedGreenGradingResult(greenGradingResult);
    }

    const onChangeSelectedCuppingResult = (sampleId: string, cuppingId: string | number) => {
        setSelectedCuppingResult((prev) => {
            const updatedSet = { ...prev };
            const cId = cuppingId.toString();
            if (updatedSet[sampleId]) {
                const updatedSampleSet = new Set(updatedSet[sampleId]);

                if (updatedSampleSet.has(cId)) {
                    updatedSampleSet.delete(cId);
                } else {
                    updatedSampleSet.add(cId);
                }

                updatedSet[sampleId] = updatedSampleSet;
            } else {
                updatedSet[sampleId] = new Set([cId]);
            }

            return updatedSet;
        });
    };

    const onChangeSelectedGreenGradingResult = (sampleId: string, greenGradingId: string | number) => {
        setSelectedGreenGradingResult((prev) => {
            const updatedSet = { ...prev };
            const gId = greenGradingId.toString();
            if (updatedSet[sampleId]) {
                const updatedSampleSet = new Set(updatedSet[sampleId]);

                if (updatedSampleSet.has(gId)) {
                    updatedSampleSet.delete(gId);
                } else {
                    updatedSampleSet.add(gId);
                }

                updatedSet[sampleId] = updatedSampleSet;
            } else {
                updatedSet[sampleId] = new Set([gId]);
            }

            return updatedSet;
        });
    };

    const clearSelectedData = () => {
        setSelectedCuppingResult({});
        setSelectedGreenGradingResult({});
    }

    const onCloseModal = () => {
        clearSelectedData();
        onClose();
    }

    const getSelectedCuppingResultIds = () => {
        const cuppingResultIds: string[] = [];
        Object.keys(selectedCuppingResult).map(key => {
            const cuppingResult = selectedCuppingResult[key];
            cuppingResult.forEach(item => {
                cuppingResultIds.push(item);
            });
        })
        return cuppingResultIds;
    }

    const getSelectedGreenGradingResultIds = () => {
        const greenGradingResultIds: string[] = [];
        Object.keys(selectedGreenGradingResult).map(key => {
            const greenGradingResult = selectedGreenGradingResult[key];
            greenGradingResult.forEach(item => {
                greenGradingResultIds.push(item);
            });
        })
        return greenGradingResultIds;
    }

    useEffect(() => {
        if (isOpen) {
            getData();
        } else {
            clearData();
        }
    }, [isOpen]);

    const isDisableGenerateReport = getSelectedCuppingResultIds().length === 0 && getSelectedGreenGradingResultIds().length === 0;

    return (
        <>
            <ModalWrapper
                open={isOpen}
                containerProps={{
                    sx: { '& .MuiAccordion-root': { boxShadow: 'none', '&:before': { display: 'none' } }, padding: '32px !important' }
                }}
            >
                <Box>
                    <Typography fontSize={20} fontWeight="600">{t('generateReport.title')}</Typography>
                    <Typography fontSize={14}>{t('generateReport.description')}</Typography>
                </Box>
                {isFetching &&
                    <Box m={3} textAlign="center">
                        <CircularProgress color="primary" />
                    </Box>
                }
                <Box marginTop='20px'>
                    {sampleData.map((sample: any, index: number) => {
                        const {
                            id, uniqueToken, name, sampleUniqueNumber, cuppingResultsMultisample, greenGradingsMultisample,
                        } = sample;
                        const isSelectedCuppingResult = selectedCuppingResult[uniqueToken] && selectedCuppingResult[uniqueToken].size > 0;
                        const isSelectedGreenGradingResult = selectedGreenGradingResult[uniqueToken] && selectedGreenGradingResult[uniqueToken].size > 0;
                        const isSelected = isSelectedCuppingResult || isSelectedGreenGradingResult;
                        return (
                            <Accordion
                                key={id}
                                sx={{
                                    border: `1px solid ${colors.royalPurple}`,
                                    borderBottom: index > 0 || sampleData.length === 1 ? `1px solid ${colors.royalPurple}` : 0,
                                    borderRadius: '0 !important',
                                    margin: '0 !important',
                                    paddingHorizontal: '16px'
                                }}>
                                <AccordionSummary sx={{
                                    height: '56px',
                                    paddingX: '16px',
                                    paddingY: '8px',
                                    '& .MuiAccordionSummary-content': { margin: '0' },
                                    '& .Mui-expanded': { margin: '0 !important' },
                                    backgroundColor: isSelected ? colors.magnoliaAccent : 'white'
                                }} expandIcon={<IconArrow />}>
                                    <AccordionDetails sx={{ paddingX: 0, paddingY: 0, width: '100%' }}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center' marginRight='8px'>
                                            <Box>
                                                <Typography>{sampleUniqueNumber}</Typography>
                                                <Typography fontWeight="600">{name}</Typography>
                                            </Box>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onDeleteSample(uniqueToken);
                                                }}
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        </Stack>
                                    </AccordionDetails>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingX: 0, paddingY: 0, width: '100%', paddingTop: '20px' }}>
                                    <CombinedCuppingResults
                                        id={uniqueToken}
                                        cuppingResultsMultisample={cuppingResultsMultisample}
                                        selectedCuppingResult={selectedCuppingResult[uniqueToken]}
                                        onChangeSelectedCuppingResult={onChangeSelectedCuppingResult}
                                    />
                                    <CombinedGreenGradingResults
                                        id={uniqueToken}
                                        greenGradingsMultisample={greenGradingsMultisample}
                                        selectedGreenGradingResult={selectedGreenGradingResult[uniqueToken]}
                                        onChangeSelectedGreenGradingResult={onChangeSelectedGreenGradingResult}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                    )}
                </Box>
                <Stack direction="row" justifyContent="end" spacing={2} mt={4}>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="contained"
                        onClick={onGenerateCombinedReport}
                        disabled={isDisableGenerateReport}
                    >
                        {t('generateReport.generate')}
                    </Button>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="outlined"
                        onClick={onCloseModal}
                    >
                        {t('common.cancel')}
                    </Button>
                </Stack>
            </ModalWrapper>
            <ReportConfigurationModal
                isOpen={isOpenReportConfiguration}
                type="combined"
                navigateUrl={navigateUrl}
                onClose={handleCloseReportConfigurationModal}
            />
        </>
    )
}
