import { validateEmail } from "utils";

export const thirdPartyEmailValidation = (emails: string): boolean => {
    if (!emails) return false;

    const emailList = emails.split(',');
    for (const email of emailList) {
        if (!validateEmail(email)) {
            return false;
        }
    }
    return true;
};