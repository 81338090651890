import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ceil, debounce } from "lodash";
import moment from "moment";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Box, Stack, Typography, Checkbox, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import ReactHtmlParser from 'react-html-parser'

import { useStores } from "models";
import { optionsTranslation } from "utils";
import { BasicTable, FormInput } from "components";

const columnHelper = createColumnHelper<any>();

interface GreenGradingsMultisample {
  data: any[],
  meta: {
    total: number
  }
}

interface CombinedGreenGradingResultsProps {
  id: string,
  onChangeSelectedGreenGradingResult: (sampleId: string, greenGradingId: string | number) => void,
  selectedGreenGradingResult: Set<string>,
  greenGradingsMultisample: GreenGradingsMultisample
}

const PAGE_SIZE = 5;

export const CombinedGreenGradingResults = observer((props: CombinedGreenGradingResultsProps) => {
  const { id, selectedGreenGradingResult = new Set(), onChangeSelectedGreenGradingResult, greenGradingsMultisample } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [isFetching, setIsFetching] = useState(false);
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const {
    greenGradingStore: { getGreenGradingsExternal },
  } = useStores();

  const initData = (greenGradings: GreenGradingsMultisample) => {
    const { data, meta } = greenGradings;
    const totalPage = ceil(meta.total / PAGE_SIZE);
    setTotalPage(totalPage);
    setData(data.splice(0, PAGE_SIZE));
    setIsInitiated(true);
  }

  const getGreenGradingsData = async (page: number, searchValue?: string) => {
    setIsFetching(true);
    try {
      const res: any = await getGreenGradingsExternal(id, PAGE_SIZE, page, searchValue || search);
      if (res.kind === "ok") {
        const totalPage = ceil(res.meta.pagination.records / PAGE_SIZE);
        setTotalPage(totalPage);
        setData(res.data);
      }
    } finally {
      setIsFetching(false)
    }
  };

  const debouncedFetch = useCallback(
      debounce((page: number, searchValue?: string) => {
        getGreenGradingsData(page, searchValue);
      }, 500),
      []
    );
  
  const onChangePage = (_, p) => {
    setPage(p);
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => <Box />,
      cell: info => {
        const value = info.renderValue();
        const isSelected = selectedGreenGradingResult.has(value.toString());
        return (
          <Box textAlign="left">
            <Checkbox
              disabled={selectedGreenGradingResult.size > 0 && !isSelected}
              checked={isSelected}
              onChange={() => onChangeSelectedGreenGradingResult(id, value)}
            />
          </Box>
        )
      }
    }),
    columnHelper.accessor('author', {
      id: 'author',
      header: () => <Box textAlign="left">{t('sample.detail.gradedBy')}</Box>,
      cell: info => <Box fontWeight={600}>{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.detail.gradedOn')}</Box>,
      cell: info => <Box>{moment(info.renderValue()).format('L')}</Box>,
    }),
    columnHelper.accessor('screenSize', {
      id: 'screenSize',
      header: () => <Box textAlign="left">{t('sample.detail.screenSize')}</Box>,
      cell: info => <Box>16/19 - {ceil(info.renderValue())}%</Box>,
    }),
    columnHelper.accessor('smell', {
      id: 'smell',
      header: () => <Box textAlign="left">{t('sample.detail.smell')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('color', {
      id: 'color',
      header: () => <Box textAlign="left">Color</Box>,
      cell: info => <Box>{info.renderValue() ? optionsTranslation('color', info.renderValue()) : ''}</Box>,
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: () => t('sample.detail.totalDefects'),
      cell: info => {
        const value = info.renderValue();
        const total = typeof value === 'object' ? value : JSON.parse(info.renderValue())
        return <Box textAlign="center">{total.all}</Box>
      },
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    initData(greenGradingsMultisample)
  }, [greenGradingsMultisample]);

  useEffect(() => {
    if (isInitiated) {
      getGreenGradingsData(page);
    }
  }, [page]);

    useEffect(() => {
      if (isInitiated) {
        setPage(1);
        debouncedFetch(1, search);
      }
      return () => debouncedFetch.cancel();
    }, [search, debouncedFetch]);

  return (
    <Box px={2} py={2.5}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography fontWeight="600">
            {t('generateReport.greenGrading.title')}
          </Typography>
          {ReactHtmlParser(t('generateReport.greenGrading.descriptionHTML'))}
        </Box>
        <FormInput
          textFieldProps={{
            placeholder: `${t('common.search')}...`,
            value: search,
            onChange: (e) => {
              setSearch(e.target.value)
            },
            InputProps: {
              endAdornment: (<InputAdornment position="end"><SearchOutlined /></InputAdornment>)
            }
          }}
          formControlProps={{ margin: 'none', fullWidth: false }}
        />
      </Stack>
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        onChangePage={onChangePage}
        isFetching={isFetching}
        paginationStyle={{ mb: 0 }}
      />
    </Box>
  )
})
