import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";

import { FormInput, ModalWrapper } from "components";
import { ThirdPartyEmailModalProps } from "./third-party-email-modal.props";
import { thirdPartyEmailValidation } from "./third-party-email-modal-utils";


export const ThirdPartyEmailModal = (props: ThirdPartyEmailModalProps) => {
    const { t } = useTranslation();
    const { onSubmit, isOpen, onClose, thirdPartyEmail } = props;

    const [emails, setEmails] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (!thirdPartyEmailValidation(emails)) {
            setError(t('shipment.modal.emailError'));
            return;
        }
        setError('');
        onClose();
        onSubmit && onSubmit(emails);
    }

    useEffect(() => {
        if (!isOpen) {
            setEmails(thirdPartyEmail || '');
        }
        setError('');
    }, [isOpen]);
    return (
        <ModalWrapper open={isOpen} maxWidth='sm'>
            <Box>
                <Typography variant="h5" textAlign="center">{t('shipment.modal.addEmail')}</Typography>
                <Typography variant="body2" textAlign="center">{t('shipment.modal.addEmailDescription')}</Typography>
                <FormInput
                    label={t('shipment.modal.email')}
                    textFieldProps={{
                        helperText: error ? error : t('shipment.modal.helperEmailText'),
                        value: emails,
                        onChange: (event) => setEmails(event.target.value),
                        error: !!error,
                    }}
                />

                <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {t(`shipment.button.submit`)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        {t("common.cancel")}
                    </Button>
                </Stack>
            </Box>
        </ModalWrapper>
    )
}
