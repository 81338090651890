import { flow, types } from "mobx-state-tree"
import { withEnvironment, withGeneralError, withSetValue } from "models/extensions"
import { ApiResult } from "services/api"
import { SampleReportStoreModel } from "models/sample-report-store";

export const CombinedReportStore = types
  .model("CombinedReportStore")
  .props({
    sampleReports: types.optional(types.array(SampleReportStoreModel), []),
  })
  .extend(withEnvironment)
  .extend(withGeneralError)
  .extend(withSetValue)
  .actions((self) => ({
    getReport: flow(function* (params) {
      try {
        self.setValue("sampleReports", []);
        const res: ApiResult = yield self.environment.combinedReportApi.all(params);

        if (res.kind === "ok") {
          // if it's single report, BE will return an object (backward compatibility)
          // if it's multiple report, BE will return an array
          const newRes = Array.isArray(res.data) ? res.data : [res.data];

          newRes.forEach((reportData) => {
            if (reportData.scoreSummary) {
              const newReport = SampleReportStoreModel.create({});
              newReport.setSampleReport(reportData.scoreSummary);
              self.sampleReports.push(newReport);
            }
          });
          return newRes;
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error);
      }
    }),
  }));
