import React from "react";
import {TabPanel} from "@mui/lab";
import {Switch, Typography, Stack} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import { observer } from "mobx-react-lite";

import {useStores} from "models";

export const ScaPolicy = observer(() => {
  const { t } = useTranslation()
  const { companyStore: { scaSync, setValue, updateScaPolicy } } = useStores()

  const handleChange = (event, checked) => {
    setValue('scaSync', checked)
    updateScaPolicy(checked)
  }

  return (
    <TabPanel value="scaPolicy">
      <Typography variant='h5' sx={{mb: 2}}>{t('scaPolicy.title')}</Typography>

      <Typography variant='body2' sx={{mb: 1}}>
        <Trans
          i18nKey={t("scaPolicy.policy")}
          values={{
            sca_policy: "https://sca.coffee/privacy-policy",
            tastify_policy: "https://www.tastify.com/privacy-police"
          }}
          components={{
            LinkSca: <a target='_blank' href='https://sca.coffee/privacy-policy'>sca_policy</a>,
            LinkTastify: <a target='_blank' href='https://www.tastify.com/privacy-police'>tastify_policy</a>
          }}
        />
      </Typography>

      <Stack direction="row" spacing={1}>
        <Switch
          checked={!!scaSync}
          onChange={handleChange}
        />
        <Typography variant='body2' sx={{mb: 1}}>{t('scaPolicy.agreement')}</Typography>
      </Stack>
    </TabPanel>
  )
})
